import React from "react";
import { DatePicker, Radio, Space, Form } from "antd";
import dayjs from "dayjs";
import locale from "antd/es/date-picker/locale/pt_BR";

const { RangePicker } = DatePicker;

const DateForm = ({
  startDate,
  endDate,
  onChangeStartDate,
  onChangeEndDate,
  grafic,
  setGrafic,
}) => (
  <>
    <Form layout="vertical">
      {/* <Form.Item>
        <Radio.Group value={grafic} onChange={(e) => setGrafic(e.target.value)}>
          <Radio.Button value="dia">Dia</Radio.Button>
          <Radio.Button value="semana">Semana</Radio.Button>
        </Radio.Group>
      </Form.Item> */}

      <Form.Item>
        <Space direction="vertical" size={12}>
          <RangePicker
            locale={locale}
            format="DD/MM/YYYY"
            value={[dayjs(startDate), dayjs(endDate)]}
            onChange={(dates) => {
              onChangeStartDate(dates[0]);
              onChangeEndDate(dates[1]);
            }}
          />
        </Space>
      </Form.Item>
    </Form>
  </>
);

export default DateForm;
