import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { Modal, Button, List, Typography } from "antd";

const { Title, Text } = Typography;

const FileListModal = ({ isOpen, onClose, files, onDeleteFile }) => {
  return (
    <Modal
      title="Arquivos Carregados"
      open={isOpen}
      onCancel={onClose}
      footer={null}
    >
      <Title level={5}>Total de arquivos carregados: {files.length}</Title>
      <List
        header={
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>Nome do arquivo</span>
            <span>Data de upload</span>
          </div>
        }
        dataSource={files}
        renderItem={(fileObj) => (
          <List.Item
            actions={[
              <Button 
                type="text" 
                danger 
                icon={<FontAwesomeIcon icon={faTrash} />} 
                onClick={() => {
                Modal.confirm({
                  title: 'Você tem certeza que deseja deletar este arquivo?',
                  okText: 'Sim',
                  cancelText: 'Não',
                  onOk: () => onDeleteFile(fileObj.file)
                });
              }}
              />
            ]}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              <Text>{fileObj.file}</Text>
              <Text>{fileObj.date}</Text>
            </div>
          </List.Item>
        )}
        locale={{ emptyText: 'Nenhum arquivo carregado' }}
      />
    </Modal>
  );
};

export default FileListModal;
