import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;
const engine = process.env.REACT_APP_ENGINE;


const apiClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Request interceptor
apiClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor
apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    /*if (error.response.status === 401) {
      // Handle unauthorized access (e.g., redirect to login)
    }*/
    return Promise.reject(error);
  }
);

apiClient.uploadFile = (url, file, client_id) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("client_id", client_id);

  return apiClient.post(url, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

apiClient.processFile = (url, client_id, sessionid) => {
  return apiClient.post(url, { client_id, sessionid, engine });
};

export default apiClient;
