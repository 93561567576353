import { useEffect, useRef, useCallback } from "react";
import { debounce } from "lodash";

const calculateInactivityLimit = (inactivityLimitInMinutes) => inactivityLimitInMinutes * 60 * 1000;

const useInactivityHandler = (onLogout, debounceDelay, inactivityLimitInMinutes = 10) => {
  const inactivityTimerRef = useRef(null);
  const inactivityLimit = calculateInactivityLimit(inactivityLimitInMinutes);

  const resetTimer = useCallback(
    debounce(() => {
      if (inactivityTimerRef.current) {
        clearTimeout(inactivityTimerRef.current);
      }
      inactivityTimerRef.current = setTimeout(() => {
        onLogout();
      }, inactivityLimit);
    }, debounceDelay),
    [onLogout, debounceDelay, inactivityLimit]
  );

  const handleUserInteraction = () => {
    resetTimer();
  };

  useEffect(() => {
    resetTimer();
    return () => {
      if (inactivityTimerRef.current) {
        clearTimeout(inactivityTimerRef.current);
      }
    };
  }, [resetTimer]);

  return { resetTimer, handleUserInteraction };
};

export { useInactivityHandler };
