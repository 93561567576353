import React from "react";
import { Form, Input, Button, Typography, Space } from "antd";
import styled from "styled-components";
import FileList from "../FileList/FileList.jsx";

const { Title } = Typography;

const StyledButton = styled(Button)`
  border-radius: 5px;
`;

const UserForm = ({
  isCreating,
  form,
  handleCreateUser,
  handleUpdateUser,
  handleCancelEdit,
  files,
  editingUser,
  handleDeleteFile
}) => {
  return (
    <>
      <Title level={4}>{isCreating ? 'Adicionar Novo Usuário' : 'Editar Usuário'}</Title>
      <Form
        form={form}
        layout="vertical"
        onFinish={isCreating ? handleCreateUser : handleUpdateUser}
        style={{ marginTop: "20px" }}
      >
        <Form.Item
          label="Nome"
          name="nome"
          rules={[{ required: true, message: "Por favor, insira o nome do usuário" }]}
        >
          <Input size="large" placeholder="Digite o nome do usuário" />
        </Form.Item>
        <Form.Item
          label="Fantasia"
          name="fantasia"
          rules={[{ required: true, message: "Por favor, insira o nome fantasia" }]}
        >
          <Input size="large" placeholder="Digite o nome fantasia" />
        </Form.Item>
        {!isCreating && (
          <>
            <Form.Item
              label="Template Contexto"
              name="template_contexto"
            >
              <Input.TextArea rows={3} placeholder="Digite o template de contexto" />
            </Form.Item>
            <Form.Item
              label="Template Internet"
              name="template_internet"
            >
              <Input.TextArea rows={3} placeholder="Digite o template de internet" />
            </Form.Item>
            <Form.Item
              label="Template Saudação"
              name="template_saudacao"
            >
              <Input.TextArea rows={3} placeholder="Digite o template de saudação" />
            </Form.Item>
            <FileList files={files} handleDeleteFile={(file) => handleDeleteFile(editingUser.id, file)} />
          </>
        )}
        <Form.Item>
          <Space direction="horizontal" style={{ width: "100%", justifyContent: "space-between" }}>
          <StyledButton type="default" onClick={handleCancelEdit} size="large">
              Cancelar
            </StyledButton>
            <StyledButton type="primary" htmlType="submit" size="large">
              {isCreating ? 'Adicionar Usuário' : 'Atualizar Usuário'}
            </StyledButton>
            
          </Space>
        </Form.Item>
      </Form>
    </>
  );
};

export default UserForm;
