import React from "react";
import { Table, Space, Button, Popconfirm, Tooltip } from "antd";
import { EditOutlined, DeleteOutlined, UploadOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { useIntl } from "react-intl";

const StyledButton = styled(Button)`
  border-radius: 5px;
`;

const UserTable = ({ usersList, handleEditUser, handleDeleteUser, setIsModalUploadOpen, setEditingUser }) => {
  const intl = useIntl();

  const columns = [
    {
      title: intl.formatMessage({ id: "userTable.name", defaultMessage: "Nome" }),
      dataIndex: "nome",
      key: "nome",
      sorter: (a, b) => a.nome.localeCompare(b.nome),
    },
    {
      title: intl.formatMessage({ id: "userTable.fantasia", defaultMessage: "Fantasia" }),
      dataIndex: "fantasia",
      key: "fantasia",
      sorter: (a, b) => a.fantasia.localeCompare(b.fantasia),
    },
    {
      title: intl.formatMessage({ id: "userTable.admin", defaultMessage: "Admin" }),
      dataIndex: "admin",
      key: "admin",
      sorter: (a, b) => a.admin - b.admin,
      render: (admin) => (admin ? intl.formatMessage({ id: "userTable.yes", defaultMessage: "Sim" }) : intl.formatMessage({ id: "userTable.no", defaultMessage: "Não" })),
    },
    {
      title: intl.formatMessage({ id: "userTable.active", defaultMessage: "Ativo" }),
      dataIndex: "active",
      key: "active",
      sorter: (a, b) => a.active - b.active,
      render: (active) => (active ? intl.formatMessage({ id: "userTable.yes", defaultMessage: "Sim" }) : intl.formatMessage({ id: "userTable.no", defaultMessage: "Não" })),
    },
    {
      title: intl.formatMessage({ id: "userTable.createdAt", defaultMessage: "Criado em" }),
      dataIndex: "created_at",
      key: "created_at",
      sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
      render: (created_at) =>
        new Date(created_at).toLocaleString("pt-BR", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        }),
    },
    {
      title: intl.formatMessage({ id: "userTable.actions", defaultMessage: "Ações" }),
      key: "actions",
      render: (_, user) => (
        <Space size="middle">
          <Tooltip title={intl.formatMessage({ id: "userTable.edit", defaultMessage: "Editar" })}>
            <StyledButton type="link" icon={<EditOutlined />} onClick={() => handleEditUser(user)} />
          </Tooltip>
          <Tooltip title={intl.formatMessage({ id: "userTable.uploadFile", defaultMessage: "Upload Arquivo" })}>
            <StyledButton
              type="link"
              icon={<UploadOutlined />}
              onClick={() => {
                setIsModalUploadOpen(true);
                setEditingUser(user);
              }}
            />
          </Tooltip>
          <Popconfirm
            title={intl.formatMessage({ id: "userTable.deleteConfirmation", defaultMessage: "Tem certeza que deseja deletar este usuário?" })}
            onConfirm={() => handleDeleteUser(user.id)}
            okText={intl.formatMessage({ id: "userTable.yes", defaultMessage: "Sim" })}
            cancelText={intl.formatMessage({ id: "userTable.no", defaultMessage: "Não" })}
          >
            <Tooltip title={intl.formatMessage({ id: "userTable.delete", defaultMessage: "Deletar" })}>
              <StyledButton type="link" danger icon={<DeleteOutlined />} />
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={usersList}
      rowKey="id"
      pagination={{ pageSize: 10 }}
    />
  );
};

export default UserTable;
