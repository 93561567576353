import React, { useState } from "react";
import { Modal, Upload, Button, Typography } from "antd";
import { InboxOutlined } from "@ant-design/icons";

const { Dragger } = Upload;
const { Text } = Typography;

const FileUploadModal = ({
  isOpen,
  onClose,
  onFileUpload,
  multiple = true,
  clientId = null,
}) => {
  const [fileList, setFileList] = useState([]);
  const [isUploading, setIsUploading] = useState(false);

  const handleUpload = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const handleConfirmUpload = async () => {
    if (fileList.length > 0 && onFileUpload) {
      setIsUploading(true);
      try {
        clientId ? await onFileUpload(clientId, fileList.map((file) => file.originFileObj)) : await onFileUpload(fileList.map((file) => file.originFileObj));
        setFileList([]);
        onClose();
      } finally {
        setIsUploading(false);
      }
    }
  };

  const handleRemoveFile = (file) => {
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);
    setFileList(newFileList);
  };

  const draggerProps = {
    name: "file",
    multiple,
    fileList,
    onChange: handleUpload,
    onRemove: handleRemoveFile,
    beforeUpload: () => false,
  };

  return (
    <Modal
      title="Upload de Arquivos"
      open={isOpen}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose} disabled={isUploading}>
          Cancelar
        </Button>,
        <Button
          key="upload"
          type="primary"
          onClick={handleConfirmUpload}
          disabled={fileList.length === 0 || isUploading}
          loading={isUploading}
        >
          Confirmar Upload
        </Button>,
      ]}
    >
      <Text>Tamanho máximo por arquivo: 50MB</Text>
      <Dragger {...draggerProps}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Clique ou arraste arquivos para esta área para fazer upload
        </p>
      </Dragger>
    </Modal>
  );
};

export default FileUploadModal;
