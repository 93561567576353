import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

const FileListContainer = styled.div`
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #f0f0f0;
  padding: 10px;
  border-radius: 5px;
  background: #fafafa;
`;

const FileItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  padding: 8px;
  border-radius: 5px;
  background: #ffffff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
`;

const FileName = styled.span``;

const FileActions = styled.div`
  display: flex;
  align-items: center;
`;

const FileDate = styled.span`
  margin-right: 10px;
`;

const DeleteButton = styled.button`
  background: none;
  border: none;
  color: red;
  cursor: pointer;
`;

const FileList = ({ files, handleDeleteFile }) => {
  return (
    <FileListContainer>
      {files.length > 0 ? (
        files.map((fileObj, index) => (
          <FileItem key={index}>
            <FileName>{fileObj.file}</FileName>
            <FileActions>
              <FileDate>{fileObj.date}</FileDate>
              <DeleteButton onClick={() => handleDeleteFile(fileObj.file)}>
                <FontAwesomeIcon icon={faTrash} />
              </DeleteButton>
            </FileActions>
          </FileItem>
        ))
      ) : (
        <div>Nenhum arquivo carregado</div>
      )}
    </FileListContainer>
  );
};

export default FileList;
