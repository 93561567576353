import styled from "styled-components";
import { Link } from "react-router-dom";

export const UserMenuContainer = styled.div`
  position: relative;
  display: inline-block;
`;

export const UserIconButton = styled.button`
  background: none;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
  padding: 10px;
`;

export const UserMenuDropdown = styled.div`
  position: absolute;
  top: 40px;
  right: 0;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  overflow: hidden;
  z-index: 1000;
  width: 14.5rem;

  @media (min-width: 1500px) {
    width: 18rem;
  }
`;

export const UserMenuItem = styled(Link)`
  display: block;
  padding: 10px 20px;
  text-decoration: none;
  color: #4f9deb;
  font-size: 12px;
  transition: background-color 0.2s;

  &:hover {
    background-color: #4f9deb;
    color: white;
  }

  @media (min-width: 1500px) {
    padding: 20px 30px;
    font-size: 1.2rem;
  }
`;

export const LogoutButton = styled.button`
  background: none;
  border: none;
  width: 100%;
  text-align: left;
  padding: 10px 20px;
  cursor: pointer;
  color: #4f9deb;
  font-size: 12px;
  transition: background-color 0.2s;

  &:hover {
    background-color: #4f9deb;
    color: white;
  }

  @media (min-width: 1500px) {
    padding: 20px 30px;
    font-size: 1.2rem;
  }
`;