import React, { useState } from "react";
import { Modal, Upload, Button, Typography } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { useIntl } from "react-intl";

const { Dragger } = Upload;
const { Text } = Typography;

const FileUploadModal = ({
  isOpen,
  onClose,
  onFileUpload,
  multiple = true,
  clientId = null,
}) => {
  const intl = useIntl();
  const [fileList, setFileList] = useState([]);
  const [isUploading, setIsUploading] = useState(false);

  const handleUpload = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const handleConfirmUpload = async () => {
    if (fileList.length > 0 && onFileUpload) {
      setIsUploading(true);
      try {
        clientId
          ? await onFileUpload(clientId, fileList.map((file) => file.originFileObj))
          : await onFileUpload(fileList.map((file) => file.originFileObj));
        setFileList([]);
        onClose();
      } finally {
        setIsUploading(false);
      }
    }
  };

  const handleRemoveFile = (file) => {
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);
    setFileList(newFileList);
  };

  const draggerProps = {
    name: "file",
    multiple,
    fileList,
    onChange: handleUpload,
    onRemove: handleRemoveFile,
    beforeUpload: () => false,
  };

  return (
    <Modal
      title={intl.formatMessage({
        id: "fileUploadModal.title",
        defaultMessage: "Upload de Arquivos",
      })}
      open={isOpen}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose} disabled={isUploading}>
          {intl.formatMessage({
            id: "fileUploadModal.cancel",
            defaultMessage: "Cancelar",
          })}
        </Button>,
        <Button
          key="upload"
          type="primary"
          onClick={handleConfirmUpload}
          disabled={fileList.length === 0 || isUploading}
          loading={isUploading}
        >
          {intl.formatMessage({
            id: "fileUploadModal.confirmUpload",
            defaultMessage: "Confirmar Upload",
          })}
        </Button>,
      ]}
    >
      <Text>
        {intl.formatMessage({
          id: "fileUploadModal.maxSize",
          defaultMessage: "Tamanho máximo por arquivo: 50MB",
        })}
      </Text>
      <Dragger {...draggerProps}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          {intl.formatMessage({
            id: "fileUploadModal.dragAreaText",
            defaultMessage: "Clique ou arraste arquivos para esta área para fazer upload",
          })}
        </p>
      </Dragger>
    </Modal>
  );
};

export default FileUploadModal;
