import React, { useEffect, useRef, useCallback } from "react";
import evaApi from "../../services/api/evaApi";
import MessageList from "../MessageList/MessageList.jsx";
import InputBox from "../InputBox/InputBox.jsx";
import {
  MainContainer,
  Content,
  ChatContainer,
  TypingIndicator,
} from "./Chatbot.js";
import useAppSession from "../../stores/useAppSession.js";
import useChat from "../../stores/useChat.js";
import { toast } from "react-toastify";

const Chatbot = ({ onSessionId, onLogout }) => {
  const { token, clientId, sessionId } = useAppSession();
  const {
    session,
    messages = [],
    isTyping,
    currentBotMessage,
    botTyping,
    habilitaEmail,
    habilitaZendesk,
    habilitaContexto,
    shouldLog,
    engine,
    setSession,
    setMessages,
    setIsTyping,
    setCurrentBotMessage,
    setBotTyping,
    addMessage,
    setShouldLog,
  } = useChat();
  const messagesEndRef = useRef(null);
  const inactiveTimerRef = useRef(null);
  const inactivityLimit = 10 * 60 * 1000;

  const resetTimer = () => {
    if (inactiveTimerRef.current) {
      clearTimeout(inactiveTimerRef.current);
    }
    inactiveTimerRef.current = setTimeout(() => {
      bye();
      setTimeout(onLogout, 60000);
    }, inactivityLimit);
  };

  const typeMessage = useCallback((message) => {
    setBotTyping(true);
    setCurrentBotMessage("");
    let index = 0;
  
    const typingInterval = setInterval(() => {
      setCurrentBotMessage((prev) => prev + message[index]);
      index++;
  
      if (index === message.length) {
        clearInterval(typingInterval);
        addMessage({ sender: "bot", text: message });
        setBotTyping(false);
        setIsTyping(false);
      }
    }, 0.1);
  }, [setBotTyping, setCurrentBotMessage, addMessage, setIsTyping]);
  
  
  
  const bye = async () => {
    setMessages([]);
    setIsTyping(true);
    
    const body = {
      query:
        "Se despeça de maneira informal para o usuário falando que por ter passado 10 min de atividade você encerrará o atendimento",
      memory: "{}",
      searchdocs: true,
      temperature: 0.1,
      template: habilitaContexto ? "template_contexto" : "template_internet",
      client_id: clientId,
      email: habilitaEmail,
      zendesk: habilitaZendesk,
      sessionid: session,
      username: `WEB {{${clientId}}}`,
      cl: process.env.REACT_APP_CL,
      engine: engine,
    };

    try {
      const response = await evaApi.askai(body, token);
      const data = response.data;
      typeMessage(data.message);
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401 || error.response.status === 403) {
          onLogout();
        } else {
          const errorMessage =
            error.response.data.message || "Ocorreu um erro inesperado.";
          toast(errorMessage);
        }
      } else {
        toast("Erro de rede. Verifique sua conexão.", {
          type: "error",
        });
      }
    }
  };

  useEffect(() => {
    resetTimer();

    return () => {
      clearTimeout(inactiveTimerRef.current);
    };
  }, [resetTimer]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView();
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages, currentBotMessage]);

  useEffect(() => {
    if (session === "") {
      if (sessionId) {
        const fetchSession = async () => {
          try {
            const response = await evaApi.getSession(sessionId);
            const sessionData = response.data.messages.sort((a, b) =>
              a.dat > b.data ? 1 : b.data > a.data ? -1 : 0
            );
            setSession(sessionId);
            setMessages([]);
            for (let i = 0; i < sessionData.length; i++) {
              const messageList = [];
              if (i === 0) {
                messageList.push({
                  sender: "bot",
                  text: sessionData[i]["resposta"],
                });
              } else {
                messageList.push({
                  sender: "user",
                  text: sessionData[i]["pergunta"],
                });
                messageList.push({
                  sender: "bot",
                  text: sessionData[i]["resposta"],
                });
              }
              addMessage(...messageList);
            }
          } catch (error) {
            console.error("Error fetching session:", error);
            const errorMessage =
              error.response.data.message || "Ocorreu um erro inesperado.";
            toast(errorMessage, {
              type: "error",
            });
          }
        };
        fetchSession();
      } else if (shouldLog) {
        setShouldLog(false);

        const grettings = async () => {
          setMessages([]);
          setIsTyping(true);

          const body = {
            query:
              "Se apresente de maneira informal para o usuário falando sobre é um assistente virtual especializado da empresa e irá ajudá-lo",
            memory:
              '{"role": "system", "content": "Você é um representante comercial querendo vender um produto ou uma franquia. Lembre todas as perguntas que o humano fizer"}',
            searchdocs: false,
            temperature: 0.9,
            template: "template_saudacao",
            client_id: clientId,
            email: false,
            zendesk: false,
            sessionid: "",
            username: `WEB {{${clientId}}}`,
            cl: process.env.REACT_APP_CL,
            engine: engine,
          };
          try {
            const response = await evaApi.askai(body, token);
            const data = response.data;
            typeMessage(data.message);
            setSession(data.sessionid);
            onSessionId(data.sessionid);
          } catch (error) {
            if (error.response) {
              if (
                error.response.status === 401 ||
                error.response.status === 403
              ) {
                onLogout();
              } else {
                const errorMessage =
                  error.response.data.message || "Ocorreu um erro inesperado.";
                toast(errorMessage);
              }
            } else {
              toast("Erro de rede. Verifique sua conexão.", {
                type: "error",
              });
            }
          }
        };

        grettings();
      }
    }
  }, [session, sessionId, clientId, token, onSessionId, onLogout, typeMessage,messages,setIsTyping,setMessages,setSession, addMessage]);

  return (
    <>
      <MainContainer>
        <Content>
          <ChatContainer>
            <MessageList
              messages={messages}
              isTyping={isTyping}
              botTyping={botTyping}
              messagesEndRef={messagesEndRef}
            />
            {isTyping && !botTyping && <TypingIndicator />}
            <InputBox />
          </ChatContainer>
        </Content>
      </MainContainer>
    </>
  );
};

export default Chatbot;
