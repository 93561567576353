import React, { useState } from "react";
import { Modal, Button, Input } from "antd";
import mediaApi from "../../services/api/mediaApi";
import useAppSession from "../../stores/useAppSession";
import { toast } from "react-toastify";
import { useIntl } from "react-intl";

const FooterButtons = ({ onClose, onConfirm, intl, loading }) => [
  <Button key="back" onClick={onClose} disabled={loading}>
    {intl.formatMessage({ id: "cancel" })}
  </Button>,
  <Button
    key="submit"
    type="primary"
    onClick={onConfirm}
    loading={loading}
    style={loading ? { cursor: "not-allowed" } : {}}
  >
    {intl.formatMessage({ id: "confirm" })}
  </Button>,
];

const YouTubeModal = ({ isOpen, onClose }) => {
  const [newUrl, setNewUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const { sessionId } = useAppSession();
  const intl = useIntl();

  const isValidYouTubeUrl = (url) => {
    try {
      const parsedUrl = new URL(url);
      const hostname = parsedUrl.hostname;
      const path = parsedUrl.pathname;
  
      const isYouTubeDomain =
        hostname === "www.youtube.com" ||
        hostname === "youtube.com" ||
        hostname === "m.youtube.com" ||
        hostname === "youtu.be";
  
      const isShorts = path.startsWith("/shorts");
  
      const isVideo =
        hostname === "youtu.be" || parsedUrl.searchParams.has("v") || path.startsWith("/watch");
  
      return isYouTubeDomain && !isShorts && isVideo;
    } catch {
      return false;
    }
  };
  

  const handleConfirm = async () => {
    if (newUrl.trim() === "") {
      toast.warning(intl.formatMessage({
        id: "youtube.add.empty",
        defaultMessage: "Por favor, insira uma URL do YouTube.",
      }));
      return;
    }
  
    if (!isValidYouTubeUrl(newUrl)) {
      toast.error(intl.formatMessage({
        id: "youtube.add.invalid.url",
        defaultMessage: "A URL fornecida não é válida ou não é compatível. Certifique-se de que seja um vídeo regular do YouTube.",
      }));
      return;
    }
  
    setLoading(true);
    try {
      await mediaApi.uploadYoutube({ url: newUrl, sessionid: sessionId });
      toast.success(intl.formatMessage({
        id: "youtube.add.success",
        defaultMessage: "Vídeo adicionado com sucesso!",
      }));
      onClose();
    } catch (error) {
      console.error("Erro ao fazer upload da URL do YouTube:", error);
      toast.error(intl.formatMessage({
        id: "youtube.add.error",
        defaultMessage: "Ocorreu um erro ao adicionar o vídeo. Tente novamente mais tarde.",
      }));
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <Modal
      title={intl.formatMessage({ id: "youtube.add.url" })}
      open={isOpen}
      onCancel={onClose}
      footer={
        <FooterButtons
          onClose={onClose}
          onConfirm={handleConfirm}
          intl={intl}
          loading={loading}
        />
      }
    >
      <div style={{ marginBottom: 16 }}>
        <Input
          placeholder={intl.formatMessage({ id: "youtube.enter.url" })}
          value={newUrl}
          onChange={(e) => setNewUrl(e.target.value)}
          disabled={loading}
          style={loading ? { cursor: "not-allowed" } : {}}
        />
      </div>
    </Modal>
  );
};

export default YouTubeModal;
