import styled, { keyframes } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const ChatMessages = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 1.5rem;
  width: calc(100% - 6rem);
  margin: 4rem 2rem 1rem 2rem;
  border-radius: 1rem;

  @media (max-width: 1200px) {
    width: calc(100% - 4rem);
    margin: 3.5rem 1.5rem 1rem 1.5rem;
  }

  @media (max-width: 900px) {
    width: calc(100% - 3rem);
    margin: 3rem 1rem;
  }

  @media (max-width: 770px) {
    width: calc(100% - 2rem);
    margin: 2.5rem 0.5rem 1rem 0.5rem;
    padding: 1rem;
  }

  @media (max-width: 600px) {
    width: calc(100% - 1rem);
    margin: 2rem 0.25rem;
    padding: 0.75rem;
  }
`;

export const MessageContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;
  padding: 10px;
  border-radius: 10px;
  align-self: ${({ className }) =>
    className === "user" ? "flex-end" : "flex-start"};
  transition: all 0.3s ease;

  &.user {
    justify-content: flex-start;
    flex-direction: row-reverse;
  }

  @media (max-width: 600px) {
    padding: 8px;
  }
`;

export const MessageText = styled.div`
  padding: 20px 16px;
  border-radius: 18px;
  max-width: 70%;
  border: none;
  margin: 5px 10px;
  background-color: ${({ className }) =>
    className === "user" ? "#2196f3" : "#f1f3f4"};
  color: ${({ className }) => (className === "user" ? "#ffffff" : "#202124")};
  position: relative;

  p {
    font-size: 16px;
    line-height: 1.4;
    margin: 0;
  }

  @media (max-width: 900px) {
    max-width: 65%;
    font-size: 14px;
  }

  @media (max-width: 600px) {
    max-width: 60%;
    padding: 8px;
    font-size: 13px;
  }
`;

export const MessageIcon = styled(FontAwesomeIcon)`
  margin-right: 12px;
  color: #757575;
  font-size: 1.2rem;

  @media (max-width: 600px) {
    margin-right: 5px;
  }
`;

const bounce = keyframes`
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
`;

export const TypingIndicator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  background-color: #f1f3f4;
  border-radius: 1rem;
  padding: 0.5rem 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

  @media (max-width: 600px) {
    margin-top: 0.5rem;
  }
`;

export const Dot = styled.div`
  height: 8px;
  width: 8px;
  margin: 0 4px;
  background-color: #757575;
  border-radius: 50%;
  animation: ${bounce} 1.4s infinite ease-in-out both;

  &:nth-child(1) {
    animation-delay: -0.32s;
  }

  &:nth-child(2) {
    animation-delay: -0.16s;
  }

  &:nth-child(3) {
    animation-delay: 0s;
  }

  @media (max-width: 600px) {
    height: 6px;
    width: 6px;
  }
`;

export const ThumbsContainer = styled.div`
  display: flex;
  gap: 8px;
  position: absolute;
  right: 10px;
  bottom: -10px;
  justify-content: flex-end;
`;

export const ThumbButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;

  &.active {
    color: #007bff;
  }

  &:hover {
    color: #0056b3;
  }
`;
