import React, { useState } from 'react';
import { Select, Typography } from 'antd';

const { Option } = Select;
const { Text } = Typography;

const ChannelFilter = ({ onChannelChange }) => {
  const [selectedChannel, setSelectedChannel] = useState('Todos');

  const handleChannelChange = (value) => {
    setSelectedChannel(value);
    onChannelChange(value);
  };

  return (
    <div style={{ marginBottom: '16px', display: 'flex', alignItems: 'center'  }}>
      <Text style={{ marginRight: '10px' }}>Filtrar por canal:</Text>
      <Select
        value={selectedChannel}
        onChange={handleChannelChange}
        style={{ width: 200, marginLeft: '10px' }}
      >
        <Option value="Todos">Todos</Option>
        <Option value="Telegram">Telegram</Option>
        <Option value="Web">Web</Option>
        <Option value="Whatsapp">Whatsapp</Option>
        <Option value="Widget">Widget</Option>
      </Select>
    </div>
  );
};

export default ChannelFilter;
