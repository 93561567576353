import React, { useState, useCallback, useRef } from "react";
import { Input, Button } from "antd";
import { SendOutlined } from "@ant-design/icons";
import evaApi from "../../services/api/evaApi";
import useChat from "../../stores/useChat.js";
import useAppSession from "../../stores/useAppSession.js";
import { useIntl } from "react-intl";

const InputBox = ({ typeMessage, usernameBody = 'WEB' }) => {
  const intl = useIntl();
  const {
    session,
    messages,
    setMessages,
    setIsTyping,
    setBotTyping,
    habilitaContexto,
    habilitaEmail,
    habilitaZendesk,
    engine,
    temperature,
  } = useChat();
  const { token, clientId, locale } = useAppSession();
  const [input, setInput] = useState("");
  const inputRef = useRef(null);

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleSendMessage = useCallback(async () => {
    if (input.trim() === "") return;

    const userMessage = { sender: "user", text: input };
    const newMessages = [...messages, userMessage];
    setMessages(newMessages);
    setInput("");
    if (inputRef.current) {
      inputRef.current.focus();
    }
    setIsTyping(true);

    const body = {
      query: input + `\n Fale em ${locale === 'en' ? 'inglês' : 'português'}`,
      memory: "{}",
      searchdocs: true,
      temperature: temperature,
      template: habilitaContexto ? "template_contexto" : "template_internet",
      client_id: clientId,
      email: habilitaEmail,
      zendesk: habilitaZendesk,
      sessionid: session,
      username: `${usernameBody} {{${clientId}}}`,
      cl: process.env.REACT_APP_CL,
      engine: engine,
    };

    try {
      const response = await evaApi.askai(body, token);
      const data = response.data;
      const botMessage = data.message;
      setBotTyping(true)
      typeMessage(botMessage);
    } catch (error) {
      if (error.response) {
        console.error("Error response:", error.response);
        typeMessage(
          intl.formatMessage({
            id: "inputBox.error.processing",
            defaultMessage: "Erro ao processar sua mensagem. Por favor, tente novamente mais tarde.",
          })
        );
      } else {
        console.error("Network error:", error);
        typeMessage(
          intl.formatMessage({
            id: "inputBox.error.network",
            defaultMessage: "Erro de rede. Verifique sua conexão.",
          })
        );
      }
    } finally {
      setIsTyping(false);
      setBotTyping(false);
    }
  }, [input, messages, setMessages, setIsTyping, locale, habilitaContexto, clientId, habilitaEmail, habilitaZendesk, session, usernameBody, engine, token, typeMessage, intl, setBotTyping]);

  return (
    <div style={{ display: "flex", marginTop: "16px", padding: "10px" }}>
      <Input
        value={input}
        onChange={handleInputChange}
        placeholder={intl.formatMessage({
          id: "inputBox.placeholder",
          defaultMessage: "Digite sua mensagem...",
        })}
        onPressEnter={handleSendMessage}
        style={{ marginRight: "8px", height: "48px", fontSize: "16px" }}
      />
      <Button
        type="primary"
        icon={<SendOutlined />}
        onClick={handleSendMessage}
        style={{ height: "48px" }}
      >
        {intl.formatMessage({
          id: "inputBox.sendButton",
          defaultMessage: "Enviar",
        })}
      </Button>
    </div>
  );
};

export default InputBox;
