import React from "react";
import { Row, Col, Statistic, Card } from "antd";

const CardGrid = ({
  totalTokens,
  totalFilesTokens,
  lastMonthTokens,
  totalMessages,
  totalFilesSize,
  totalMessagesSize,
  averageTokens,
}) => (
  <Row gutter={[16, 16]}>
    {[
      { title: "Tokens mensagens", value: totalTokens },
      { title: "Tokens arquivos", value: totalFilesTokens },
      { title: "Tokens do último mês", value: lastMonthTokens },
      { title: "Total tokens", value: totalTokens + totalFilesTokens },
      { title: "Mensagens no período", value: totalMessages * 2 },
      { title: "Arquivos enviados (kB)", value: totalFilesSize, precision: 1 },
      { title: "Média Tokens/Mês", value: averageTokens },
      {
        title: "Mensagens enviadas (kB)",
        value: totalMessagesSize,
        precision: 1,
      },
    ].map((item, index) => (
      <Col xs={24} sm={12} md={8} lg={6} key={index}>
        <Card
          style={{
            borderRadius: "12px",
            boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div>
            <h3
              style={{
                marginBottom: "8px",
                fontSize: "16px",
                color: "#8c8c8c",
              }}
            >
              {item.title}
            </h3>
            <p style={{ fontSize: "24px", fontWeight: "bold", margin: 0 }}>
              {isNaN(item.value)
                ? 0
                : Number.isInteger(item.value)
                ? item.value
                : item.value.toFixed(2).replace(".", ",")}
            </p>
          </div>
        </Card>
      </Col>
    ))}
  </Row>
);

export default CardGrid;
