import React, { useEffect, useState, useRef } from "react";
import apiTokenConsumption from "../../services/api/apiTokenConsumption";
import evaApi from "../../services/api/evaApi";
import CardGrid from "../CardGrId/CardGrid.jsx";
import ChartSection from "../ChartSection/CharSection.jsx";
import DateForm from "../DateForm/DateForm.jsx";
import Footer from "../Footer/Footer.jsx";

import { Background } from "./Dashboard";
import useAppSession from "../../stores/useAppSession.js";
import { Space, Typography, Spin } from "antd";
import dayjs from "dayjs";

const Dashboard = ({ onLogout }) => {
  const { clientId, sessionId } = useAppSession();
  const [data, setData] = useState(null);
  const [startDate, setStartDate] = useState(dayjs().subtract(6, "month"));
  const [endDate, setEndDate] = useState(dayjs());
  const [messagesLength, setMessagesLength] = useState(0);
  const [grafic, setGrafic] = useState("dia");
  const [count, setCount] = useState(0);
  const [refreshInterval, setRefreshInterval] = useState(60);
  const inactiveTimerRef = useRef(null);
  const inactivityLimit = 60 * 60 * 1000;

  useEffect(() => {
    const resetTimer = () => {
      if (inactiveTimerRef.current) {
        clearTimeout(inactiveTimerRef.current);
      }
      inactiveTimerRef.current = setTimeout(() => {
        onLogout();
      }, inactivityLimit);
    };
    resetTimer();

    return () => {
      clearTimeout(inactiveTimerRef.current);
    };
  }, []);

  const fetchData = async () => {
    try {
      const response = await apiTokenConsumption.get_tokens(
        clientId,
        startDate.format("YYYY-MM-DD"),
        endDate.format("YYYY-MM-DD")
      );
      if (response.status === 401 || response.status === 403) {
        onLogout();
      }
      const result = response;
      setData(result.data);

      const responseMesssages = await evaApi.getMessages(
        clientId,
        startDate.format("YYYY-MM-DD"),
        endDate.format("YYYY-MM-DD")
      );
      if (
        responseMesssages.status === 401 ||
        responseMesssages.status === 403
      ) {
        onLogout();
      }
      const messagesLen = responseMesssages.data.messages.length;
      setMessagesLength(messagesLen);
    } catch (error) {
      console.error("Error fetching token consumption data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [clientId, startDate, endDate]);

  const handleRefresh = () => {
    setCount((prevCount) => prevCount + 1);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      handleRefresh();
    }, refreshInterval * 1000);

    return () => clearInterval(intervalId);
  }, [refreshInterval, count]);

  if (!data)
    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spin size="large" />
        <Typography.Text style={{ marginTop: "16px" }}>
          Carregando...
        </Typography.Text>
      </div>
    );
  // Process the data for the chart
  const processedData = data.consumption.reduce(
    (acc, item) => {
      acc.labels.push(`${item.day}/${item.month.toString().padStart(2, "0")}`);
      acc.values.push(item.message_tokens);
      return acc;
    },
    { labels: [], values: [] }
  );

  const processedDataFiles = data.consumption.reduce(
    (acc, item) => {
      acc.labels.push(`${item.year}-${item.month.toString().padStart(2, "0")}`);
      acc.values.push(item.file_tokens);
      return acc;
    },
    { labels: [], values: [] }
  );

  const processedTotalTokens = data.consumption.reduce(
    (acc, item) => {
      acc.labels.push(`${item.year}-${item.month.toString().padStart(2, "0")}`);
      acc.values.push(item.message_tokens);
      return acc;
    },
    { labels: [], values: [] }
  );

  const processedSizeFiles = data.consumption.reduce(
    (acc, item) => {
      acc.labels.push(`${item.year}-${item.month.toString().padStart(2, "0")}`);
      acc.values.push(item.file_size);
      return acc;
    },
    { labels: [], values: [] }
  );

  const processedSizeMessages = data.consumption.reduce(
    (acc, item) => {
      acc.labels.push(`${item.year}-${item.month.toString().padStart(2, "0")}`);
      acc.values.push(item.message_size);
      return acc;
    },
    { labels: [], values: [] }
  );

  function joinFirstAndLastEverySeven(arr) {
    const result = [];

    for (let i = 0; i < arr.length; i += 7) {
      const chunk = arr.slice(i, i + 7);
      if (chunk.length > 1) {
        result.push(`${chunk[0]} - ${chunk[chunk.length - 1]}`);
      } else if (chunk.length === 1) {
        result.push(chunk[0]);
      }
    }

    return result;
  }

  function sumEverySeven(arr) {
    const result = [];

    for (let i = 0; i < arr.length; i += 7) {
      const chunk = arr.slice(i, i + 7);
      const sum = chunk.reduce((acc, val) => acc + val, 0);
      result.push(sum);
    }

    return result;
  }

  const chartData = {
    labels:
      grafic === "dia"
        ? processedData.labels
        : joinFirstAndLastEverySeven(processedData.labels),
    datasets: [
      {
        label: "Tokens Consumed",
        data:
          grafic === "dia"
            ? processedTotalTokens.values
            : sumEverySeven(processedTotalTokens.values),
        borderColor: "rgb(79, 157, 235)",
        tension: 0.3,
      },
    ],
  };

  const totalMessagesSize = processedSizeMessages.values.reduce(
    (sum, value) => sum + value,
    0
  );

  const totalTokens = processedTotalTokens.values.reduce(
    (sum, value) => sum + value,
    0
  );

  const totalFilesTokens = processedDataFiles.values.reduce(
    (sum, value) => sum + value,
    0
  );

  const totalFilesSize = processedSizeFiles.values.reduce(
    (sum, value) => sum + value,
    0
  );

  const averageTokens = Math.round(totalTokens / processedData.values.length);
  const lastMonthTokens =
    processedData.values[processedData.values.length - 1] +
    processedDataFiles.values[processedDataFiles.values.length - 1];


  return (
    <>
      <Background>
        <Space
          style={{
            width: "100%",
            marginBottom: "16px",
          }}
        >
          <Typography.Title level={2}>Dashboard</Typography.Title>
        </Space>
        <DateForm
          startDate={startDate}
          endDate={endDate}
          onChangeStartDate={setStartDate}
          onChangeEndDate={setEndDate}
          grafic={grafic}
          setGrafic={setGrafic}
        />

        <CardGrid
          totalTokens={totalTokens}
          totalFilesTokens={totalFilesTokens}
          lastMonthTokens={lastMonthTokens}
          totalMessages={messagesLength}
          totalFilesSize={totalFilesSize}
          totalMessagesSize={totalMessagesSize}
          averageTokens={averageTokens}
        />
        <ChartSection grafic={grafic} data={chartData} />
      </Background>
      <Footer />
    </>
  );
};

export default Dashboard;
