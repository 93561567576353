import React, { useState } from 'react';
import { Select, Typography } from 'antd';
import { useIntl } from 'react-intl';

const { Option } = Select;
const { Text } = Typography;

const ChannelFilter = ({ onChannelChange }) => {
  const [selectedChannel, setSelectedChannel] = useState('Todos');
  const intl = useIntl();

  const handleChannelChange = (value) => {
    setSelectedChannel(value);
    onChannelChange(value);
  };

  return (
    <div style={{ marginBottom: '16px', display: 'flex', alignItems: 'center' }}>
      <Text style={{ marginRight: '10px' }}>
        {intl.formatMessage({ id: 'filter.by.channel', defaultMessage: 'Filtrar por canal:' })}
      </Text>
      <Select
        value={selectedChannel}
        onChange={handleChannelChange}
        style={{ width: 200, marginLeft: '10px' }}
      >
        <Option value="Todos">
          {intl.formatMessage({ id: 'filter.all', defaultMessage: 'Todos' })}
        </Option>
        <Option value="Telegram">
          {intl.formatMessage({ id: 'filter.telegram', defaultMessage: 'Telegram' })}
        </Option>
        <Option value="Web">
          {intl.formatMessage({ id: 'filter.web', defaultMessage: 'Web' })}
        </Option>
        <Option value="Whatsapp">
          {intl.formatMessage({ id: 'filter.whatsapp', defaultMessage: 'Whatsapp' })}
        </Option>
        <Option value="Widget">
          {intl.formatMessage({ id: 'filter.widget', defaultMessage: 'Widget' })}
        </Option>
      </Select>
    </div>
  );
};

export default ChannelFilter;
