import styled from "styled-components";

export const BodyLogin = styled.div`
  display: flex;
  height: 100vh;
  

  @media (max-width: 770px) {
    flex-direction: column;
    height: auto;
    background-color: white;
  }
`;

export const ComercialContext = styled.div`
  width: 60%;
  background-color: #d9e9fb;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3rem;

  @media (max-width: 770px) {
    margin-top: 3rem;
    max-width: 600px;
    padding: 1rem 0;
    background-color: white;
  }

  @media (min-width: 1500px) {
    h2 {
      font-size: 2.5rem;
    }
    h3 {
      font-size: 1.5rem;
    }
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 400px;
  gap: 1rem;

  @media (max-width: 770px) {
    padding: 1rem 0;
    height: auto;
    gap: 0.5rem;
    align-items:center;
    width: 100vw;

    h3{
    text-align:center;
    }
  }

  @media (min-width: 1500px) {
    height: 520px;
  }
`;

export const ComercialImage = styled.img`
  width: 90%;

  @media (max-width: 770px) {
    width: 100vw;
    display:none;
  }
`;

export const LoginContext = styled.div`
  display: flex;
  align-items: center;
  padding: 3rem;
  width: 40%;

  @media (max-width: 770px) {
    text-align: center;
    width: auto;
    padding: 2rem;
  }
`;

export const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 400px;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 770px) {
    height: auto;
    gap:1rem;
  }

  @media (min-width: 1500px) {
    height: 520px;

    h2 {
      font-size: 2.5rem;
    }
  }
`;

export const LoginInputContainer = styled.div`
  position: relative;
  margin-bottom: 20px;
`;

export const LoginInput = styled.input`
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  height: 30px;
  border-radius: 26px;
  width: calc(100% - 20px);
  background: transparent;
  outline: none;
  transition: border-color 0.3s, padding-top 0.3s;

  &:focus {
    border-color: #4f9deb;
  }

  @media (max-width: 770px) {
    font-size: 12px;
  }

  @media (min-width: 1500px) {
    height: 50px;
    font-size: 1.1rem;
    padding: 10px 20px;
    width: calc(100% - 40px);
  }
`;

export const LoginLabel = styled.label`
  position: absolute;
  left: 15px;
  top: 12px;
  font-size: 14px;
  color: transparent;
  transition: 0.3s;
  pointer-events: none;

  ${LoginInput}:focus + &,
  ${LoginInput}:not(:placeholder-shown) + & {
    top: -10px;
    font-size: 11px;
    color: #4f9deb;
    background: white;
    padding: 0 5px;
    border-radius: 5px;

    @media (min-width: 1500px) {
      font-size: 1.15rem;
      padding: 0 5px;
      border-radius: 15px;
      margin: 0 10px;
    }
  }

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

export const LoginButton = styled.button`
  padding: 10px;
  background-color: #4f9deb;
  color: #fff;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  height: 40px;
  width: 50%;
  transition: box-shadow 0.3s, transform 0.4s;

  &:active {
    box-shadow: 0 0 20px 5px rgba(79, 157, 235, 0.8);
    transform: scale(0.98);
  }

  @media (max-width: 768px) {
    width: 100%;
  }

  @media (min-width: 1500px) {
    height: 3.2rem;
    font-size: 1.3rem;
  }
`;

export const ErrorMessage = styled.p`
  visibility: hidden;
  color: red;
  height: 1em;

  &.show {
    visibility: visible;
  }

  @media (min-width: 1500px) {
    font-size: 1.2rem;
  }
`;

export const Footer = styled.footer`
  background-color: transparent;
  position: fixed;
  bottom: 20px;
  z-index: 50;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;

  @media (max-width: 770px) {
    bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
  }
`;

export const FooterLogo = styled.div`
  display: flex;
  gap: 1.5rem;
  align-items: center;
  font-size: 11px;

  @media (max-width: 770px) {
    gap: 1rem;
    font-size: 0.8rem;
    width: 100vw;
    justify-content: center;
  }

  @media (min-width: 1500px) {
    font-size: 1.5rem;
    padding: 15px 0;

    img:first-of-type {
      height: 44px;
    }

    img:nth-of-type(2) {
      height: 66px;
    }
  }
`;
 
export const LoginPage = styled.div`
  @media (max-width: 770px) {
    height: 100vh;
    width: 100vw;
    background-color: white;
  }
`