import React, { useEffect, useState } from "react";
import Markdown from "react-markdown";
import { faUser, faRobot, faThumbsUp, faThumbsDown } from "@fortawesome/free-solid-svg-icons";
import {
  ChatMessages,
  MessageContainer,
  MessageText,
  TypingIndicator,
  Dot,
  MessageIcon,
  ThumbsContainer,
  ThumbButton,
} from "./MessageList"; 
import useChat from "../../stores/useChat.js";
import useAppSession from "../../stores/useAppSession.js";
import evaApi from "../../services/api/evaApi";

const MessageList = ({
  messages = [],
  isTyping,
  botTyping,
  messagesEndRef,
}) => {
  const { currentBotMessage } = useChat();
  const { sessionId, clientId } = useAppSession();
  const [feedback, setFeedback] = useState({});

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView();
  }, [messages, isTyping, botTyping, currentBotMessage, messagesEndRef]);

  const handleThumbClick = (messageIndex, type) => {
    const currentFeedback = feedback[messageIndex];
    const newFeedback = currentFeedback === type ? null : type;

    setFeedback((prevFeedback) => ({
      ...prevFeedback,
      [messageIndex]: newFeedback,
    }));

    const sendFeedbackToApi = async () => {
      try {
        const body = {
          id: messages[messageIndex].id,
          sessionid: sessionId,
          client_id: clientId,
          rating: newFeedback,
        };
        const response = await evaApi.rate(body);
        console.assert(response);
      } catch (error) {
        console.error(error);
      }
    };

    sendFeedbackToApi();
  };

  return (
    <ChatMessages>
       {messages.map((message, index) => (
        <MessageContainer key={index} className={message.sender}>
          <MessageIcon icon={message.sender === "user" ? faUser : faRobot} />
          <MessageText className={message.sender}>
            <Markdown>{message.text}</Markdown>
            {message.sender === "bot" && (
              <ThumbsContainer style={{ position: "relative", right: "0", bottom: "0" }}>
                <ThumbButton
                  onClick={() => handleThumbClick(index, 0)}
                  className={feedback[index] === 0 ? "active" : ""}
                  
                >
                  <MessageIcon icon={faThumbsDown} style={{ color: feedback[index] === 0 ? "#5daff0" : "#c9c9c9" }} />
                </ThumbButton>
                <ThumbButton
                  onClick={() => handleThumbClick(index, 1)}
                  className={feedback[index] === 1 ? "active" : ""}
                >
                  <MessageIcon icon={faThumbsUp} style={{ color: feedback[index] === 1 ? "#5daff0" : "#c9c9c9" }} />
                </ThumbButton>
              </ThumbsContainer>
            )}
          </MessageText>
        </MessageContainer>
      ))}

      {botTyping && currentBotMessage && (
        <MessageContainer className="bot">
          <MessageIcon icon={faRobot} />
          <MessageText>
            <Markdown>{currentBotMessage}</Markdown>
          </MessageText>
        </MessageContainer>
      )}

      {isTyping && !botTyping && (
        <MessageContainer className="bot">
          <MessageIcon icon={faRobot} />
          <TypingIndicator>
            <Dot />
            <Dot />
            <Dot />
          </TypingIndicator>
        </MessageContainer>
      )}

      <div ref={messagesEndRef} />
    </ChatMessages>
  );
};

export default MessageList;
