import React from "react";
import { Button, Popover } from "antd";
import { UserOutlined, LogoutOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import useAppSession from "../../stores/useAppSession";

const SidebarProfile = ({ collapsed }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { clearSession, isAdmin } = useAppSession();

  const handleLogout = () => {
    clearSession();
    navigate("/login");
  };

  const profileContent = (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {isAdmin && (
        <Button type="text" icon={<UserOutlined />} onClick={() => navigate("/user-profile")}>
          {intl.formatMessage({ id: "sidebar.profile", defaultMessage: "Perfil" })}
        </Button>
      )}
      <Button type="text" icon={<LogoutOutlined />} onClick={handleLogout}>
        {intl.formatMessage({ id: "sidebar.logout", defaultMessage: "Logout" })}
      </Button>
    </div>
  );

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        marginBottom: "20px",
      }}
    >
      <Popover content={profileContent} trigger="click" placement="rightTop">
        <Button
          type="text"
          style={{
            fontSize: "16px",
            color: "white",
            backgroundColor: "rgba(255, 255, 255, 0.2)",
            borderRadius: "10px",
            display: "flex",
            alignItems: "center",
            width: "auto",
            margin: 10,
          }}
        >
          <UserOutlined style={{ fontSize: "20px" }} />
          {!collapsed && intl.formatMessage({ id: "sidebar.profile", defaultMessage: "Perfil" })}
        </Button>
      </Popover>
    </div>
  );
};

export default SidebarProfile;