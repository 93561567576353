import React from "react";
import { Table, Space, Button, Popconfirm, Tooltip } from "antd";
import { EditOutlined, DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import styled from "styled-components";

const StyledButton = styled(Button)`
  border-radius: 5px;
`;

const UserTable = ({ usersList, handleEditUser, handleDeleteUser, setIsModaUploadlOpen, setEditingUser }) => {
  const columns = [
    {
      title: 'Nome',
      dataIndex: 'nome',
      key: 'nome',
      sorter: (a, b) => a.nome.localeCompare(b.nome),
    },
    {
      title: 'Fantasia',
      dataIndex: 'fantasia',
      key: 'fantasia',
      sorter: (a, b) => a.fantasia.localeCompare(b.fantasia),
    },
    {
      title: 'Admin',
      dataIndex: 'admin',
      key: 'admin',
      sorter: (a, b) => a.admin - b.admin,
      render: (admin) => (admin ? 'Sim' : 'Não'),
    },
    {
      title: 'Ativo',
      dataIndex: 'active',
      key: 'active',
      sorter: (a, b) => a.active - b.active,
      render: (active) => (active ? 'Sim' : 'Não'),
    },
    {
      title: 'Criado em',
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
      render: (created_at) => new Date(created_at).toLocaleString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' }),
    },
    
    {
      title: 'Ações',
      key: 'actions',
      render: (_, user) => (
        <Space size="middle">
          <Tooltip title="Editar">
            <StyledButton type="link" icon={<EditOutlined />} onClick={() => handleEditUser(user)} />
          </Tooltip>
          <Tooltip title="Upload Arquivo">
            <StyledButton type="link" icon={<UploadOutlined />} onClick={() => { setIsModaUploadlOpen(true); setEditingUser(user); }} />
          </Tooltip>
          <Popconfirm
            title="Tem certeza que deseja deletar este usuário?"
            onConfirm={() => handleDeleteUser(user.id)}
            okText="Sim"
            cancelText="Não"
          >
            <Tooltip title="Deletar">
              <StyledButton type="link" danger icon={<DeleteOutlined />} />
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={usersList}
      rowKey="id"
      pagination={{ pageSize: 10 }}
    />
  );
};

export default UserTable;
