import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import Chatbot from "./components/Chatbot/Chatbot.jsx";
import Login from "./components/Login/Login.jsx";
import UserProfile from "./components/UserProfile/UserProfile.jsx";
import Dashboard from "./components/Dashboard/Dashboard.jsx";
import Messageboard from "./components/Messageboard/Messageboard.jsx";
import Widget from "./components/Widget/Widget.jsx";
import useAppSession from "./stores/useAppSession.js";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminLayout from "./components/AdminLayout/AdminLayout.jsx";
import userApi from "./services/api/userApi.js";
import Workspace from "./components/Workspace/Workspace.jsx";

const App = () => {
  return (
    <Router>
      <RoutesComponent />
    </Router>
  );
};

const RoutesComponent = () => {
  const location = useLocation();
  const {
    token,
    clientId,
    sessionId,
    isAdmin,
    setToken,
    setClientId,
    setSessionId,
    setIsAdmin,
    clearSession,
  } = useAppSession();

  const handleLoginSuccess = async (token, client_id) => {
    try {
      const response = await userApi.getCurrentUser(client_id);
      const isAdmin = response.data.user.admin;
      setIsAdmin(isAdmin);
      localStorage.setItem("userName", response.data.user.admin);
    } catch (err) {
      console.error(err);
    }
  
    setToken(token);
    setClientId(client_id);
    localStorage.setItem("token", token);
  };
  

  const handleLogout = () => {
    clearSession();
  };

  const handleSessionId = (session) => {
    setSessionId(session);
  };

  if (!token && location.pathname !== "/widget") {
    return <Login onLoginSuccess={handleLoginSuccess} />;
  }

  return (
    <>
      <Routes>
        <Route
          path="/login"
          element={<Login onLoginSuccess={handleLoginSuccess} />}
        />
        <Route
          path="/"
          element={
            <AdminLayout>
              <Chatbot onSessionId={handleSessionId} />
            </AdminLayout>
          }
        />
        <Route
          path="/user-profile"
          element={
            <AdminLayout>
              <UserProfile onLogout={handleLogout}/>
            </AdminLayout>
          }
        />
        {isAdmin && (
          <>
            <Route
              path="/dashboard"
              element={
                <AdminLayout>
                  <Dashboard onLogout={handleLogout} />
                </AdminLayout>
              }
            />
            <Route
              path="/messageboard"
              element={
                <AdminLayout>
                  <Messageboard onLogout={handleLogout}/>
                </AdminLayout>
                
              }
            />
            <Route
              path="/workspace"
              element={
                <AdminLayout>
                  <Workspace onLogout={handleLogout} />
                </AdminLayout>
              }
            />
          </>
        )}
        <Route
          path="/widget"
          element={
            <Widget
              clientId={clientId}
              sessionId={sessionId}
              onSessionId={handleSessionId}
            />
          }
        />
        {/* Redirecionar para a página inicial se o usuário não for admin */}
        {!isAdmin && (
          <Route path="*" element={<Navigate to="/" />} />
        )}

      </Routes>
      <ToastContainer />
    </>
  );
};

export default App;
