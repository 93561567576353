import styled from "styled-components";

export const FooterContainer = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  margin-top: 2rem;
  flex-direction: column;

  @media (max-width: 768px) {
    padding: 0.5rem;
  }

  @media (max-width: 600px) {
    padding: 0.25rem;
  }
`;

export const FooterText = styled.span`
  margin-right: 1rem;
  font-size: 1rem;
  color: #4f9deb;
  font-weight: bold;

  @media (max-width: 1024px) {
    font-size: 0.9rem;
  }

  @media (max-width: 768px) {
    font-size: 0.8rem;
  }

  @media (max-width: 600px) {
    font-size: 0.7rem;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.5rem;
  color: #4f9deb;
  font-weight: bold;

  @media (max-width: 768px) {
    gap: 1rem;
  }

  @media (max-width: 600px) {
    gap: 0.5rem;
  }
`;

export const Logo = styled.img`
  height: ${({ height }) => height || "30px"};
  object-fit: contain;

  @media (max-width: 1024px) {
    height: ${({ height }) => height || "25px"};
  }

  @media (max-width: 768px) {
    height: ${({ height }) => height || "20px"};
  }

  @media (max-width: 600px) {
    height: ${({ height }) => height || "15px"};
  }
`;
