import React, { useState } from "react";
import userApi from "../../services/api/userApi";
import Header from "../Header/Header.jsx";
import Logo from '../../images/automatizacao-do-atendimento.png';
import MaxxiLogo from '../../images/Logo_2024_Fundos_Claros.png';
import Mundo365 from '../../images/Mundo-365-Parceiro-Microsoft-do-Brasil.png';
import { BodyLogin,
  ComercialContext,
  ComercialImage,
  InfoContainer,
  LoginContext,
  LoginContainer,
  LoginButton,
  LoginInput,
  LoginInputContainer,
  LoginLabel,
  ErrorMessage,
  Footer,
  FooterLogo,
  LoginPage
 } from "./Login.js";

const Login = ({ onLoginSuccess }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleLogin = async () => {
    try {
      const response = await userApi.login(username, password);
      if (response.status !== 200) {
        throw new Error("Falha no login");
      }

      const data = response.data;
      const token = data.message;
      onLoginSuccess(token, username);
    } catch (error) {
      setError("Falha no login. Verifique suas credenciais.");
    }
  };

  return (
    <LoginPage>
      <Header showUserMenu={false} />
      <BodyLogin>
        <ComercialContext>
          <InfoContainer>
            <h2>Engine Virtual Attendant</h2>
            <h3>Uma nova era de inovação e disrupção do atendimento digital está aqui.</h3>
            <ComercialImage src={Logo} alt="" />
          </InfoContainer>
        </ComercialContext>
        <LoginContext>
          <LoginContainer>
            <h2>Login</h2>
            <ErrorMessage className={error ? "show" : ""}>{error}</ErrorMessage>
            <LoginInputContainer>
              <LoginInput
                type="text"
                value={username}
                onChange={handleUsernameChange}
                placeholder="Usuário"
              />
              <LoginLabel>Usuário</LoginLabel>
            </LoginInputContainer>
            <LoginInputContainer>
              <LoginInput
                type="password"
                value={password}
                onChange={handlePasswordChange}
                placeholder="Senha"
              />
              <LoginLabel>Senha</LoginLabel>
            </LoginInputContainer>
            <LoginButton onClick={handleLogin}>Entrar</LoginButton>
          </LoginContainer>
        </LoginContext>
      </BodyLogin>
      <Footer>
         Powered by:
        <FooterLogo>
          
          <img src={MaxxiLogo} alt="" height="30px" />
          <img src={Mundo365} alt="" height="45px" />
        </FooterLogo>
      </Footer>
    </LoginPage>
  );
};

export default Login;
