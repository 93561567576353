import styled from "styled-components";

export const ChartContainer = styled.div`
  margin-top: 1rem;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
  width: 100%;
`;

export const ChartTitle = styled.h2`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 1rem;
`;