import React, { useState } from "react";
import { Modal, Button, Input } from "antd";
import mediaApi from "../../services/api/mediaApi";
import useAppSession from "../../stores/useAppSession";
import { toast } from "react-toastify";

const FooterButtons = ({ onClose, onConfirm }) => (
  [
    <Button key="back" onClick={onClose}>
      Cancelar
    </Button>,
    <Button key="submit" type="primary" onClick={onConfirm}>
      Confirmar
    </Button>,
  ]
);

const YouTubeModal = ({ isOpen, onClose }) => {
  const [newUrl, setNewUrl] = useState("");
  const { sessionId } = useAppSession();
  
    const handleConfirm = async () => {
    if (newUrl.trim() !== "") {
      try {
        await mediaApi.uploadYoutube({ url: newUrl, sessionid: sessionId });
        toast.success('Vídeo adicionado com sucesso!');
        onClose();
      } catch (error) {
        console.error("Erro ao fazer upload da URL do YouTube:", error);
        toast.error('Erro ao adicionar vídeo');
      }
    } else {
      onClose();
    }
  };

  return (
    <Modal
      title="Adicionar URL do YouTube"
      open={isOpen}
      onCancel={onClose}
      onOk={handleConfirm}
      footer={<FooterButtons onClose={onClose} onConfirm={handleConfirm} />}
    >
      <div style={{ marginBottom: 16 }}>
        <Input
          placeholder="Insira a URL do YouTube"
          value={newUrl}
          onChange={(e) => setNewUrl(e.target.value)}
        />
      </div>
    </Modal>
  );
};

export default YouTubeModal;
