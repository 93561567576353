import apiClient from "./apiClient";

const mediaApi = {
  uploadYoutube: (body = {}) => {
    return apiClient.post(`/api/media/upload`, body);
  },
 
};

export default mediaApi;
