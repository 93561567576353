import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { UserIconButton, UserMenuContainer, UserMenuDropdown, UserMenuItem, LogoutButton } from "./UserMenu";
export default function UserMenu({ onLogout = null }) {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleLogout = () => {
    if (onLogout) {
      onLogout();
      setMenuOpen(false);
    }
  };

  return (
    <UserMenuContainer>
      <UserIconButton onClick={toggleMenu}>
        <FontAwesomeIcon icon={faUser} style={{ color: "#757575" }} />
      </UserIconButton>
      {menuOpen && (
        <UserMenuDropdown>
          <UserMenuItem to="/user-profile" onClick={toggleMenu}>
            Visualizar/Alterar Dados
          </UserMenuItem>
          <LogoutButton onClick={handleLogout}>Logout</LogoutButton>
        </UserMenuDropdown>
      )}
    </UserMenuContainer>
  );
}
