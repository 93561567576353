import React from "react";
import { Form, Input, Button, Typography, Space } from "antd";
import styled from "styled-components";
import { useIntl } from "react-intl";
import FileList from "../FileList/FileList.jsx";

const { Title } = Typography;

const StyledButton = styled(Button)`
  border-radius: 5px;
`;

const UserForm = ({
  isCreating,
  form,
  handleCreateUser,
  handleUpdateUser,
  handleCancelEdit,
  files,
  editingUser,
  handleDeleteFile
}) => {
  const intl = useIntl();

  return (
    <>
      <Title level={4}>
        {isCreating
          ? intl.formatMessage({
              id: "userForm.addNewUser",
              defaultMessage: "Adicionar Novo Usuário"
            })
          : intl.formatMessage({
              id: "userForm.editUser",
              defaultMessage: "Editar Usuário"
            })}
      </Title>
      <Form
        form={form}
        layout="vertical"
        onFinish={isCreating ? handleCreateUser : handleUpdateUser}
        style={{ marginTop: "20px" }}
      >
        <Form.Item
          label={intl.formatMessage({
            id: "userForm.nameLabel",
            defaultMessage: "Nome"
          })}
          name="nome"
          rules={[
            {
              required: true,
              message: intl.formatMessage({
                id: "userForm.nameRequired",
                defaultMessage: "Por favor, insira o nome do usuário"
              })
            }
          ]}
        >
          <Input
            size="large"
            placeholder={intl.formatMessage({
              id: "userForm.namePlaceholder",
              defaultMessage: "Digite o nome do usuário"
            })}
          />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({
            id: "userForm.fantasiaLabel",
            defaultMessage: "Fantasia"
          })}
          name="fantasia"
          rules={[
            {
              required: true,
              message: intl.formatMessage({
                id: "userForm.fantasiaRequired",
                defaultMessage: "Por favor, insira o nome fantasia"
              })
            }
          ]}
        >
          <Input
            size="large"
            placeholder={intl.formatMessage({
              id: "userForm.fantasiaPlaceholder",
              defaultMessage: "Digite o nome fantasia"
            })}
          />
        </Form.Item>
        {!isCreating && (
          <>
            <Form.Item
              label={intl.formatMessage({
                id: "userForm.templateContextLabel",
                defaultMessage: "Template Contexto"
              })}
              name="template_contexto"
            >
              <Input.TextArea
                rows={3}
                placeholder={intl.formatMessage({
                  id: "userForm.templateContextPlaceholder",
                  defaultMessage: "Digite o template de contexto"
                })}
              />
            </Form.Item>
            <Form.Item
              label={intl.formatMessage({
                id: "userForm.templateInternetLabel",
                defaultMessage: "Template Internet"
              })}
              name="template_internet"
            >
              <Input.TextArea
                rows={3}
                placeholder={intl.formatMessage({
                  id: "userForm.templateInternetPlaceholder",
                  defaultMessage: "Digite o template de internet"
                })}
              />
            </Form.Item>
            <Form.Item
              label={intl.formatMessage({
                id: "userForm.templateGreetingLabel",
                defaultMessage: "Template Saudação"
              })}
              name="template_saudacao"
            >
              <Input.TextArea
                rows={3}
                placeholder={intl.formatMessage({
                  id: "userForm.templateGreetingPlaceholder",
                  defaultMessage: "Digite o template de saudação"
                })}
              />
            </Form.Item>
            <FileList
              files={files}
              handleDeleteFile={(file) => handleDeleteFile(editingUser.id, file)}
            />
          </>
        )}
        <Form.Item>
          <Space
            direction="horizontal"
            style={{ width: "100%", justifyContent: "space-between" }}
          >
            <StyledButton type="default" onClick={handleCancelEdit} size="large">
              {intl.formatMessage({
                id: "userForm.cancelButton",
                defaultMessage: "Cancelar"
              })}
            </StyledButton>
            <StyledButton type="primary" htmlType="submit" size="large">
              {isCreating
                ? intl.formatMessage({
                    id: "userForm.addUserButton",
                    defaultMessage: "Adicionar Usuário"
                  })
                : intl.formatMessage({
                    id: "userForm.updateUserButton",
                    defaultMessage: "Atualizar Usuário"
                  })}
            </StyledButton>
          </Space>
        </Form.Item>
      </Form>
    </>
  );
};

export default UserForm;
