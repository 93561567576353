import React, { useState, useEffect } from "react";
import ChatBotWidget from "../ChatBotWidget/ChatBotWidget.jsx";
import { FloatingIcon, FloatingWidget, CloseWidgetButton, WidgetContainer } from "./Widget.js";

const Widget = ({ onSessionId }) => {
  const [showWidget, setShowWidget] = useState(false);
  const [token, setToken] = useState(null);
  const [clientId, setClientId] = useState(null);
  const [sessionId, setSessionId] = useState("");

  useEffect(() => {
    const receiveMessage = (event) => {
      if (event.data && event.data.token) {
        setToken(event.data.token);
        localStorage.setItem("token", event.data.token);
        setClientId(event.data.clientId);
      }
    };

    window.addEventListener("message", receiveMessage);

    return () => {
      window.removeEventListener("message", receiveMessage);
    };
  }, []);

  const toggleWidget = () => {
    setShowWidget(!showWidget);
    window.parent.postMessage("toggle-widget", "*");
  };

  return (
    <>
      {!showWidget && (
        <FloatingIcon onClick={toggleWidget}>
          <img
            src="https://icons.iconarchive.com/icons/pictogrammers/material/128/robot-outline-icon.png"
            alt="Chat Icon"
          />
        </FloatingIcon>
      )}

      {showWidget && (
        <FloatingWidget>
          <CloseWidgetButton onClick={toggleWidget}>×</CloseWidgetButton>
          <WidgetContainer>
            <ChatBotWidget
              token={token}
              clientId={clientId}
              sessionId={sessionId}
              onSessionId={onSessionId}
            />
          </WidgetContainer>
        </FloatingWidget>
      )}
    </>
  );
};

export default Widget;
