import React, { useState, useCallback, useRef } from "react";
import { Input, Button } from "antd";
import { SendOutlined } from "@ant-design/icons";
import evaApi from "../../services/api/evaApi";
import useChat from "../../stores/useChat.js";
import useAppSession from "../../stores/useAppSession.js";

const InputBox = ({usernameBody='WEB'}) => {
  const {
    session,
    messages,
    setMessages,
    setIsTyping,
    setBotTyping,
    habilitaContexto,
    habilitaEmail,
    habilitaZendesk,
    engine,
  } = useChat();
  const { token, clientId } = useAppSession();
  const [input, setInput] = useState("");
  const inputRef = useRef(null);

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleSendMessage = useCallback(async () => {
    if (input.trim() === "") return;

    const userMessage = { sender: "user", text: input };
    const newMessages = [...messages, userMessage];
    setMessages(newMessages);
    setInput("");
    if (inputRef.current) {
      inputRef.current.focus();
    }
    setIsTyping(true);
    const body = {
      query: input,
      memory: "{}",
      searchdocs: true,
      temperature: 0.1,
      template: habilitaContexto ? "template_contexto" : "template_internet",
      client_id: clientId,
      email: habilitaEmail,
      zendesk: habilitaZendesk,
      sessionid: session,
      username: `${usernameBody} {{${clientId}}}`,
      cl: process.env.REACT_APP_CL,
      engine: engine,
    };

    try {
      const response = await evaApi.askai(body, token);
      const data = response.data;
      const botMessage = data.message;

      setMessages([...newMessages, { sender: "bot", text: botMessage, id: data.id }]);
    } catch (error) {
      if (error.response) {
        console.error("Error response:", error.response);
        setMessages([...newMessages, { sender: "bot", text: "Erro ao processar sua mensagem. Por favor, tente novamente mais tarde." }]);
      } else {
        console.error("Network error:", error);
        setMessages([...newMessages, { sender: "bot", text: "Erro de rede. Verifique sua conexão." }]);
      }
    } finally {
      setIsTyping(false);
      setBotTyping(false);
    }
  }, [input, messages, clientId, token, session, habilitaContexto, habilitaEmail, habilitaZendesk, setMessages, setIsTyping, setBotTyping]);

  return (
    <div style={{ display: "flex", marginTop: "16px", padding: "10px" }}>
      <Input
        value={input}
        onChange={handleInputChange}
        placeholder="Digite sua mensagem..."
        onPressEnter={handleSendMessage}
        style={{ marginRight: "8px", height: "48px", fontSize: "16px" }}
      />
      <Button
        type="primary"
        icon={<SendOutlined />}
        onClick={handleSendMessage}
        style={{ height: "48px" }}
      >
        Enviar
      </Button>
    </div>
  );
};

export default InputBox;
