import styled , { keyframes} from 'styled-components';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const WidgetHeader = styled.div`
  width: 100%;
  margin: 10px;
  text-align: center;
  display: flex;
  gap: 7.5rem;
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: calc(100% - 15rem);
`;

export const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  overflow: hidden;
`;

export const ChatMessages = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 0 1.5rem;

`;

export const Message = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;
  padding: 10px 0;
  gap: 10px;
  color: gray;

  &.user {
    justify-content: flex-start;
    flex-direction: row-reverse;
  }
  
  &.bot {
    justify-content: flex-start;
  }
`;

export const MessageText = styled.div`
  padding: 10px;
  border-radius: 5px;
  color: #757575;
  border: 1px solid #4f9deb;
  border-radius: 1.5rem;
  position: relative;
  
`;

export const FeedbackIcons = styled.div`
  display: flex;
  position: absolute;
  right: 20px;
  bottom: -8px;
  .thumb-icon {
    cursor: pointer;
    font-size: 13px;
    color: #ccc;
    margin: 0 4px;
    transition: color 0.3s ease;
    &.active {
      color: #4f9deb !important;
    }
    &:hover {
      color: lightblue;
    }
  }
    .fa-thumbs-down {
    margin-top: 2px; // Coloca o thumbs down 5px abaixo
  }
`;

export const NewChatContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 0.5rem;
`;

export const NewChatButton = styled.button`
  height: 2.5rem;
  background-color: #4f9deb;
  color: white;
  border-radius: 1.5rem;
  border: none;
  width: 80%;
  font-size: 0.8rem;
  cursor: pointer;
`;

export const InputBox = styled.div`
  display: flex;
  justify-content: center;
  padding: 5px 10px;
  position: relative;
`;

export const SendButton = styled.button`
  padding: 10px 20px;
  background-color: #4f9deb;
  color: #fff;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  margin-left: 10px;
  position: absolute;
  right: 30px;
  top: 28%;
  font-size: 12px;
`;

export const MenuContainer = styled.div`
  position: relative;
  display: inline-block;
`;

export const MenuIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
  font-size: 20px;
  margin-left: 10px;
`;

export const MenuDropdown = styled.div`
  position: absolute;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  width: 10rem;
`;

export const MenuDropdownList = styled.ul`
  list-style-type: none;
  padding: 10px;
  margin: 0;
`;

export const MenuDropdownItem = styled.li`
  padding: 8px 16px;
  cursor: pointer;
  font-size: 12px;
  &:hover {
    background-color: #4f9deb;
    color: white;
  }
`;

export const TextInput = styled.input`
  flex: 1;
  padding: 10px;
  font-size: 12px;
  border-radius: 25px;
  border: 1px solid rgba(79, 157, 235, 0.5);
  width: 100%;
  height: 30px;
  transition: border-color 0.3s ease;
  position: relative;
  
  &:focus {
    border-color: #4f9deb;
    outline: none;
  }
`;
const typing = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
`;

export const TypingIndicator = () => {
  return (
    <TypingContainer>
      <Dot />
      <Dot />
      <Dot />
    </TypingContainer>
  );
};

const TypingContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 50px;
`;

const Dot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #4f9deb;
  animation: ${typing} 0.8s infinite ease-in-out;

  &:nth-child(1) {
    animation-delay: 0s;
  }
  &:nth-child(2) {
    animation-delay: 0.2s;
  }
  &:nth-child(3) {
    animation-delay: 0.4s;
  }
`;