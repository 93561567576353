import React, { useEffect, useState, useCallback } from "react";
import { Layout, Button, Spin } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import SidebarMenu from "./SidebarMenu";
import SidebarProfile from "./SidebarProfile";
import SidebarModals from "./SidebarModals";
import EvaLogo from "../../images/EVA.png";
import useAppSession from "../../stores/useAppSession";
import { useNavigate } from "react-router-dom";
import userApi from "../../services/api/userApi.js";
import { useIntl } from "react-intl";

const { Sider } = Layout;

const Sidebar = ({ children }) => {
  const intl = useIntl();
  const [collapsed, setCollapsed] = useState(false);
  const [youtubeModal, setYoutubeModal] = useState(false);
  const [uploadModal, setUploadModal] = useState(false);
  const [fileListModal, setFileListModal] = useState(false);
  const [isAdminLoading, setIsAdminLoading] = useState(true);
  const { clientId, isAdmin, setIsAdmin } = useAppSession();
  const navigate = useNavigate();

  // Memorizar função de toggle para evitar recriação constante
  const toggleCollapsed = useCallback(() => {
    setCollapsed((prev) => !prev);
  }, []);

  // Funções de abrir e fechar modais memorizadas com useCallback
  const handleYoutubeModal = useCallback((isOpen) => {
    setYoutubeModal(isOpen);
  }, []);

  const handleUploadModal = useCallback((isOpen) => {
    setUploadModal(isOpen);
  }, []);

  const handleFileListModal = useCallback((isOpen) => {
    setFileListModal(isOpen);
  }, []);

  // Fetch de dados do usuário apenas uma vez
  useEffect(() => {
    if (isAdmin !== null) {
      setIsAdminLoading(false);
      return;
    }

    const fetchUserData = async () => {
      setIsAdminLoading(true);
      try {
        const response = await userApi.getCurrentUser(clientId);
        const isUserAdmin = response.data.user.admin;
        setIsAdmin(isUserAdmin);
        localStorage.setItem("isAdmin", isUserAdmin);
      } catch (error) {
        console.error("Erro ao buscar dados do usuário:", error);
      } finally {
        setIsAdminLoading(false);
      }
    };

    fetchUserData();
  }, [isAdmin, clientId, setIsAdmin]);

  // Mostrar loading enquanto os dados do admin estão sendo carregados
  if (isAdminLoading) {
    return (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div style={{ display: "flex" }}>
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        breakpoint="lg"
        width={280}
        collapsedWidth={80}
        style={{ height: "100vh", position: "fixed", left: 0 }}
      >
        <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
          {/* Top content */}
          <div>
            {/* Logo container and profile button */}
            <div
              className="logo-container"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "10px",
              }}
            >
              {!collapsed && (
                <>
                  <img
                    src={EvaLogo}
                    alt="Logo"
                    style={{ width: "40px", marginLeft: 20 }}
                  />
                  <span style={{ color: "white", fontSize: 20, fontWeight: 200 }}>
                    {intl.formatMessage({ id: "sidebar.assistant", defaultMessage: "Assistente" })}
                  </span>
                </>
              )}
              <Button
                type="primary"
                onClick={toggleCollapsed}
                style={{ marginLeft: collapsed ? "10px" : "0" }}
              >
                {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              </Button>
            </div>
            {process.env.REACT_APP_AMBIENTE && !collapsed && (
              <span
                style={{
                  color: "white",
                  fontSize: 12,
                  fontWeight: 200,
                  marginLeft: 30,
                }}
              >
                {process.env.REACT_APP_AMBIENTE}
              </span>
            )}
            <SidebarProfile collapsed={collapsed} />
            <SidebarMenu
              collapsed={collapsed}
              isAdmin={isAdmin}
              openYoutube={handleYoutubeModal}
              openFileList={handleFileListModal}
              openUpload={handleUploadModal}
            />
          </div>
          <SidebarModals
            isYoutubeModalOpen={youtubeModal}
            isFileListModalOpen={fileListModal}
            isUploadModalOpen={uploadModal}
            openYoutube={handleYoutubeModal}
            openFileList={handleFileListModal}
            openUpload={handleUploadModal}
          />
        </div>
      </Sider>
      <div style={{ marginLeft: collapsed ? 80 : 280, width: "100%" }}>
        {children}
      </div>
    </div>
  );
};

export default Sidebar;
