import React, { useEffect, useRef, useCallback, useState } from "react";
import { Menu, Dropdown } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import evaApi from "../../services/api/evaApi";
import MessageList from "../MessageList/MessageList.jsx";
import InputBox from "../InputBox/InputBox.jsx";
import {
  MainContainer,
  Content,
  ChatContainer,
  TypingIndicator,
} from "../Chatbot/Chatbot.js";
import {
  NewChatButton,
  NewChatContainer,
} from "./ChatBotWidget";
import useChat from "../../stores/useChat";
import useAppSession from "../../stores/useAppSession.js";
import EvaLogo from "../../images/EVA.png";

const ChatBotWidget = ({ token, clientId, onSessionId }) => {
  const {
    session,
    messages,
    isTyping,
    currentBotMessage,
    botTyping,
    habilitaEmail,
    habilitaZendesk,
    engine,
    setSession,
    setMessages,
    setIsTyping,
    setCurrentBotMessage,
    setBotTyping,
    addMessage,
  } = useChat();
  const { sessionId } = useAppSession();
  
  const [input, setInput] = useState("");
  const [conversationEnded, setConversationEnded] = useState(false);
  const messagesEndRef = useRef(null);
  const shouldLog = useRef(true);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages, currentBotMessage]);

  const typeMessage = useCallback((message) => {
    setBotTyping(true);
    setCurrentBotMessage("");
    let index = 0;

    const typingInterval = setInterval(() => {
      setCurrentBotMessage((prev) => prev + message[index]);
      index++;

      if (index === message.length) {
        clearInterval(typingInterval);
        addMessage({ sender: "bot", text: message });
        setBotTyping(false);
        setIsTyping(false);
      }
    }, 0.1);
  }, [setBotTyping, setCurrentBotMessage, addMessage, setIsTyping]);

  useEffect(() => {
    if (session === "") {
      if (sessionId) {
        const fetchSession = async () => {
          try {
            const response = await evaApi.getSession(sessionId);
            const sessionData = response.data.messages.sort((a, b) =>
              a.dat > b.data ? 1 : b.data > a.data ? -1 : 0
            );
            setSession(sessionId);
            addMessage(sessionData.map(item => ({
              sender: item.pergunta ? "user" : "bot",
              text: item.pergunta || item.resposta,
              rating: null
            })));
          } catch (error) {
            console.error("Error fetching session:", error);
          }
        };
        fetchSession();
      } else if (shouldLog.current) {
        shouldLog.current = false;
        const grettings = async () => {
          try {
            setMessages([]);
            setIsTyping(true);

            const body = {
              query:
                "Se apresente de maneira informal para o usuário falando sobre é um assistente virtual especializado da empresa e irá ajudá-lo",
              memory:
                '{"role": "system", "content": "Você é um representante comercial querendo vender um produto ou uma franquia. Lembre todas as perguntas que o humano fizer"}',
              searchdocs: false,
              temperature: 0.9,
              template: "template_saudacao",
              client_id: clientId,
              email: habilitaEmail,
              zendesk: habilitaZendesk,
              sessionid: "",
              username: `WIDGET {{${clientId}}}`,
              cl: process.env.REACT_APP_CL,
              engine: engine,
            };

            const response = await evaApi.askai(body, token);
            if (response && response.data) {
              typeMessage(response.data.message);
              setSession(response.data.sessionid);
              onSessionId(response.data.sessionid);
            } else {
              throw new Error("Resposta da API inválida");
            }
          } catch (error) {
            console.error("Erro ao iniciar a saudacão: ", error);
            setIsTyping(false);
          }
        };
        grettings();
      }
    }
  }, [session, sessionId, clientId, token, onSessionId, typeMessage, setSession, setMessages, setIsTyping]);

  const handleNewChat = () => {
    shouldLog.current = true;
    setSession("");
    onSessionId("");
    setConversationEnded(false);
    setMessages([]);
    setInput("");
  };

  const menu = (
    <Menu>
      <Menu.Item key="newChat" onClick={handleNewChat}>
        Iniciar Novo Chat
      </Menu.Item>
      <Menu.Item key="endChat" onClick={() => { setConversationEnded(true); }}>
        Encerrar Chat
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <MainContainer>
        <div style={{ position: "relative" }}>
          <Dropdown overlay={menu} trigger={["click"]}>
            <MoreOutlined style={{ fontSize: "24px", position: "absolute", top: 10, left: 10, cursor: "pointer" }} />
          </Dropdown>
          <img src={EvaLogo} alt="EVA Logo" style={{ position: "absolute", top: 10, left: "50%", transform: "translateX(-50%)", height: "18px" }} />
        </div>
        <Content>
          <ChatContainer>
            <MessageList
              messages={messages}
              isTyping={isTyping}
              botTyping={botTyping}
              messagesEndRef={messagesEndRef}
            />
            {isTyping && !botTyping && <TypingIndicator />}
            {!conversationEnded ? (
              <InputBox usernameBody="WIDGET"/>
            ) : (
              <NewChatContainer>
                <NewChatButton onClick={handleNewChat}>Iniciar uma nova conversa</NewChatButton>
              </NewChatContainer>
            )}
          </ChatContainer>
        </Content>
      </MainContainer>
    </>
  );
};

export default ChatBotWidget;
