import React from "react";
import MaxxiLogo from "../../images/Logo_2024_Fundos_Claros.png";
import Mundo365 from "../../images/Mundo-365-Parceiro-Microsoft-do-Brasil.png";
import { FooterContainer, FooterText, LogoContainer, Logo } from "./Footer";


const Footer = () => (
  <FooterContainer>
    <FooterText>Powered by:</FooterText>
    <LogoContainer>
      <Logo src={MaxxiLogo} alt="Maxxi Logo" height="30px" />
      <Logo src={Mundo365} alt="Mundo365 Logo" height="45px" />
    </LogoContainer>
  </FooterContainer>
);

export default Footer;
