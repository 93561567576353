import styled from "styled-components";

export const FloatingIcon = styled.div`
  position: fixed;
  background-color: #4f9deb;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1003;
  img {
    width: 50px;
    height: 50px;
  }
`;

export const FloatingWidget = styled.div`
  position: fixed;
  width: 400px;
  height: 600px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  border-radius: 10px;
  border: 1px solid #ccc;
  background-color: white;
`;

export const CloseWidgetButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #4f9deb;
  border: none;
  border-radius: 50%;
  color: white;
  cursor: pointer;
  font-size: 16px;
  padding: 5px;
  width: 1.5rem;
  height: 1.5rem;
  z-index: 1005;
`;

export const WidgetContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;