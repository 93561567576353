import React, { useState, useEffect } from "react";
import FileUploadModal from "../FileUploadModal/FileUploadModal.jsx";
import FileListModal from "../FileListModal/FileListModal.jsx";
import YouTubeModal from "../YoutubeModal/YoutubeModal.jsx";
import useAppSession from "../../stores/useAppSession";
import useChat from "../../stores/useChat";
import fileApi from "../../services/api/fileApi.js";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";

const SidebarModals = ({ isFileListModalOpen, isUploadModalOpen, isYoutubeModalOpen, openYoutube, openFileList, openUpload }) => {
  const [files, setFiles] = useState([]);
  const [youtubeUrl, setYouTubeUrl] = useState([]);
  const [loading, setLoading] = useState(false);
  const { clientId } = useAppSession();
  const { session } = useChat();
  const intl = useIntl();

  // Efeito para buscar arquivos quando o modal for aberto
  useEffect(() => {
    const fetchFiles = async () => {
      setLoading(true);
      try {
        const response = await fileApi.getFiles(clientId);

        if (response.status !== 200) {
          throw new Error(
            intl.formatMessage({
              id: "sidebar.errorLoadingFiles",
              defaultMessage: "Erro ao carregar arquivos",
            })
          );
        }

        const data = await response.data;
        const arrFiles = Object.entries(data).map(([file, date]) => ({ file, date }));
        setFiles(arrFiles);
      } catch (error) {
        console.error("Erro ao carregar arquivos:", error);
        toast.error(
          intl.formatMessage({
            id: "sidebar.errorLoadingFiles",
            defaultMessage: "Erro ao carregar arquivos",
          })
        );
      } finally {
        setLoading(false);
      }
    };

    if (isFileListModalOpen) {
      fetchFiles();
    }
  }, [isFileListModalOpen, clientId, intl]);

  // Função para upload de arquivos
  const handleFileUpload = async (files) => {
    if (files && files.length > 0) {
      setLoading(true);
      try {
        await Promise.all(files.map((file) => fileApi.uploadFile(file, clientId)));
        const ret = await fileApi.processFile(clientId, session);
        if (ret.status === 200) {
          toast.success(
            intl.formatMessage({
              id: "sidebar.fileProcessedSuccess",
              defaultMessage: "Arquivo processado com sucesso!",
            })
          );
          // Atualiza a lista de arquivos após o upload e processamento bem-sucedido
          setFiles((prevFiles) => [...prevFiles, ...files.map((file) => ({ file: file.name, date: new Date().toISOString() }))]);
        }
      } catch (error) {
        console.error("Erro ao fazer upload dos arquivos:", error);
        toast.error(
          intl.formatMessage({
            id: "sidebar.errorUploadingFiles",
            defaultMessage: "Erro ao fazer upload dos arquivos.",
          })
        );
      } finally {
        setLoading(false);
      }
    }
  };

  // Função para deletar arquivos
  const deleteFile = async (fileId) => {
    setLoading(true);
    try {
      await fileApi.deleteFile(clientId, fileId);
      setFiles((prevFiles) => prevFiles.filter((file) => file.file !== fileId));
      toast.success(
        intl.formatMessage({
          id: "sidebar.fileDeletedSuccess",
          defaultMessage: "Arquivo deletado com sucesso.",
        })
      );
    } catch (error) {
      console.error("Erro ao deletar o arquivo:", error);
      toast.error(
        intl.formatMessage({
          id: "sidebar.errorDeletingFile",
          defaultMessage: "Ocorreu um erro ao deletar o arquivo.",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <FileUploadModal
        isOpen={isUploadModalOpen}
        onClose={() => openUpload(false)}
        onFileUpload={handleFileUpload}
        loading={loading}
      />
      <FileListModal
        isOpen={isFileListModalOpen}
        onClose={() => openFileList(false)}
        files={files}
        onDeleteFile={deleteFile}
        loading={loading}
      />
      <YouTubeModal
        isOpen={isYoutubeModalOpen}
        onClose={() => openYoutube(false)}
        youtubeUrls={youtubeUrl}
        setYouTubeUrls={setYouTubeUrl}
      />
    </>
  );
};

export default SidebarModals;
