import { create } from "zustand";
import { persist } from "zustand/middleware";

const useAppSession = create(
  persist(
    (set) => ({
      token: null,
      clientId: null,
      sessionId: null,
      isAdmin: null,
      setToken: (token) => set({ token }),
      setClientId: (clientId) => set({ clientId }),
      setSessionId: (sessionId) => set({ sessionId }),
      setIsAdmin: (isAdmin) => set({isAdmin}),
      clearSession: () => set({ token: null, clientId: null, sessionId: null, isAdmin: null }),
    }),
    {
      name: "app-session",
      getStorage: () => localStorage,
    }
  )
);

export default useAppSession;
