import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import { ChartContainer } from "./ChartSection";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Typography } from "antd";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const ChartSection = ({ grafic, data }) => {
  const [pageWidth, setPageWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setPageWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <ChartContainer>
      <div
        style={{
          paddingTop: 10,
          paddingLeft: 10,
        }}
      >
        <Typography.Title
          style={{
            padding: 0,
            margin: 0,
          }}
          level={3}
        >
          Resumo
        </Typography.Title>
        <Typography.Text
          style={{
            padding: 0,
            margin: 0,
          }}
          level={5}
        >
          Consumo de tokens por {grafic}
        </Typography.Text>
      </div>
      <div
        style={{
          height: "400px",
          width: `100%`,
        }}
      >
        <Line
          style={{
            padding: 10
          }}
          data={{
            ...data,
            datasets: data.datasets.map((dataset) => ({
              ...dataset,
              fill: true,
              borderColor: "#336CEC",
              backgroundColor: "#FF8F3F",
              fillColor: "#FF8F3F",
            })),
          }}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              y: {
                beginAtZero: true,
                grid: {
                  color: "rgba(0, 0, 0, 0.1)",
                },
              },
              x: {
                grid: {
                  color: "rgba(0, 0, 0, 0.1)",
                },
              },
            },
            plugins: {
              legend: {
                position: "bottom",
              },
            },
          }}
        />
      </div>
    </ChartContainer>
  );
};

export default ChartSection;
