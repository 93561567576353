import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { Modal, Button, List, Typography } from "antd";
import { useIntl } from "react-intl";

const { Title, Text } = Typography;

const FileListModal = ({ isOpen, onClose, files, onDeleteFile }) => {
  const intl = useIntl();

  return (
    <Modal
      title={intl.formatMessage({
        id: "fileListModal.title",
        defaultMessage: "Arquivos Carregados",
      })}
      open={isOpen}
      onCancel={onClose}
      footer={null}
    >
      <Title level={5}>
        {intl.formatMessage(
          {
            id: "fileListModal.totalFiles",
            defaultMessage: "Total de arquivos carregados: {total}",
          },
          { total: files.length }
        )}
      </Title>
      <List
        header={
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>
              {intl.formatMessage({
                id: "fileListModal.fileNameHeader",
                defaultMessage: "Nome do arquivo",
              })}
            </span>
            <span>
              {intl.formatMessage({
                id: "fileListModal.uploadDateHeader",
                defaultMessage: "Data de upload",
              })}
            </span>
          </div>
        }
        dataSource={files}
        renderItem={(fileObj) => (
          <List.Item
            actions={[
              <Button
                type="text"
                danger
                icon={<FontAwesomeIcon icon={faTrash} />}
                onClick={() => {
                  Modal.confirm({
                    title: intl.formatMessage({
                      id: "fileListModal.deleteConfirmation",
                      defaultMessage:
                        "Você tem certeza que deseja deletar este arquivo?",
                    }),
                    okText: intl.formatMessage({
                      id: "fileListModal.confirmYes",
                      defaultMessage: "Sim",
                    }),
                    cancelText: intl.formatMessage({
                      id: "fileListModal.confirmNo",
                      defaultMessage: "Não",
                    }),
                    onOk: () => onDeleteFile(fileObj.file),
                  });
                }}
              />,
            ]}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Text>{fileObj.file}</Text>
              <Text>{fileObj.date}</Text>
            </div>
          </List.Item>
        )}
        locale={{
          emptyText: intl.formatMessage({
            id: "fileListModal.noFiles",
            defaultMessage: "Nenhum arquivo carregado",
          }),
        }}
      />
    </Modal>
  );
};

export default FileListModal;
