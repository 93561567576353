import React, { useState, useEffect, useCallback } from "react";
import userApi from "../../services/api/userApi.js";
import fileApi from "../../services/api/fileApi.js";
import useAppSession from "../../stores/useAppSession.js";
import { Form, Typography, Row, Col, Divider } from "antd";
import { toast } from "react-toastify";
import { PlusOutlined } from '@ant-design/icons';
import FileUploadModal from "../FileUploadModal/FileUploadModal.jsx";
import { useInactivityHandler } from "../../services/utils/inactivityHandler.js";
import UserTable from "../UserTable/UserTable.jsx";
import UserForm from "../UserForm/UserForm.jsx";
import {
  StyledContent,
  UserManagementSection,
  EditUserSection,
  StyledButton
} from "./Workspace.js";
import { useIntl } from 'react-intl'; 

const { Title } = Typography;

const DEBOUNCE_DELAY = 300;

const Users = ({ onLogout }) => {
  const intl = useIntl(); // Criando uma instância do hook para uso posterior
  const { sessionId } = useAppSession();
  const { handleUserInteraction } = useInactivityHandler(onLogout, DEBOUNCE_DELAY);

  const [form] = Form.useForm();
  const [editForm] = Form.useForm();
  const [usersList, setUsersList] = useState([]);
  const [editingUser, setEditingUser] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [userFormData, setUserFormData] = useState({});
  const [files, setFiles] = useState([]);
  const [isModalUploadOpen, setIsModalUploadOpen] = useState(false);

  const fetchUsersList = useCallback(async () => {
    try {
      const response = await userApi.getUsersList();
      if (response.status === 200) {
        const users = response.data.users;
        setUsersList(users);
      } else {
        throw new Error(intl.formatMessage({ id: "users.fetch.error", defaultMessage: "Erro ao buscar a lista de usuários" }));
      }
    } catch (error) {
      toast.error(intl.formatMessage({ id: "users.fetch.error.message", defaultMessage: "Erro ao buscar a lista de usuários: " }) + error.message);
    }
  }, [intl]);

  const handleFileUpload = async (clientId, files) => {
    if (onFileUpload) {
      await onFileUpload(clientId, files);
    }
  };

  const onFileUpload = async (clientId, files) => {
    if (files && files.length > 0) {
      try {
        await Promise.all(files.map(file => fileApi.uploadFile(file, clientId)));
        let ret = await fileApi.processFile(clientId, sessionId);
        if (ret.status === 200) {
          toast(intl.formatMessage({ id: "users.fileUpload.success", defaultMessage: "Arquivo processado com sucesso!" }));
        }
      } catch (error) {
        toast.error(intl.formatMessage({ id: "users.fileUpload.error", defaultMessage: "Erro ao processar o arquivo: " }) + error.message);
      }
    }
  };


  const fetchFiles = async (clientId) => {
    try {
      const response = await fileApi.getFiles(clientId);
      if (response.status !== 200) {
        throw new Error("Erro ao carregar arquivos");
      }
      const data = response.data;
      const arrFiles = [];
      Object.entries(data).forEach(([file, date]) => {
        arrFiles.push({ file, date });
      });
      setFiles(arrFiles);
    } catch (error) {
      console.error("Erro ao carregar arquivos:", error);
    }
  };

  const handleDeleteFile = async (fileId) => {
    try {
      await fileApi.deleteFile(editingUser?.id, fileId);
      toast.success("Arquivo deletado com sucesso!");
      setFiles(files.filter((file) => file.file !== fileId));
    } catch (error) {
      toast.error("Erro ao deletar o arquivo: " + error.message);
    }
  };

  useEffect(() => {
    fetchUsersList();

    return () => {
      clearTimeout(handleUserInteraction);
    };
  }, []);

  const handleCreateUser = async (values) => {
    try {
      const response = await userApi.createNewUser(values.nome, values.fantasia);

      if (response.status === 201) {
        toast.success(intl.formatMessage({ id: "users.create.success", defaultMessage: "Usuário criado com sucesso!" }));
        form.resetFields();
        fetchUsersList();
        setIsCreating(false);
      } else {
        throw new Error(intl.formatMessage({ id: "users.create.error", defaultMessage: "Erro desconhecido ao criar o usuário" }));
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage || intl.formatMessage({ id: "users.create.defaultError", defaultMessage: "Erro ao criar o usuário. Tente novamente." }));
    }
  };

  const handleEditUser = async (user) => {
    setEditingUser(user);
    setIsEditing(true);
    setIsCreating(false);
    const formData = {
      id: user.id,
      client_id: user.client_id,
      nome: user.nome,
      fantasia: user.fantasia,
      api_key: user.api_key,
      active: user.active,
      template_contexto: user.template_contexto,
      template_internet: user.template_internet,
      template_saudacao: user.template_saudacao,
    };
    setUserFormData(formData);
    editForm.setFieldsValue(formData);
    if (files.length === 0) { await fetchFiles(user.client_id); }
    handleUserInteraction();
  };

  const handleUpdateUser = async () => {
    try {
      const updatedUserData = {
        ...userFormData,
        ...editForm.getFieldsValue(),
      };
      const response = await userApi.updateProfile(updatedUserData);
      if (response.status === 200) {
        toast.success("Usuário atualizado com sucesso!");
        setEditingUser(null);
        setIsEditing(false);
        fetchUsersList();
      } else {
        throw new Error("Erro desconhecido ao atualizar o usuário");
      }
    } catch (error) {
      console.error("Erro ao salvar dados do usuário:", error);
      toast.error("Erro ao atualizar o usuário: " + error.message);
    }
  };

  const handleDeleteUser = async (userId) => {
    try {
      const response = await userApi.deleteUser(userId);
      if (response.status === 200) {
        toast.success("Usuário deletado com sucesso!");
        fetchUsersList();
      } else {
        throw new Error("Erro desconhecido ao deletar o usuário");
      }
    } catch (error) {
      toast.error("Erro ao deletar o usuário: " + error.message);
    }
  };

  const handleCancelEdit = () => {
    setEditingUser(null);
    setIsEditing(false);
    setIsCreating(false);
  };

  const handleNewUser = () => {
    setIsCreating(true);
    setIsEditing(false);
    form.resetFields();
  };

  return (
    <StyledContent style={{ backgroundColor: 'white' }} onClick={handleUserInteraction} onKeyPress={handleUserInteraction}>
      <Row
        gutter={[16, 16]}
        justify="space-around"
        style={{ flexDirection: isEditing || isCreating ? (window.innerWidth >= 1030 ? 'row-reverse' : 'column') : 'column', flexWrap: 'wrap' }}
      >
        {(isEditing || isCreating) && (
          <Col xs={24} lg={6} style={{ width: '100%' }}>
            <EditUserSection style={{ height: '100%', minHeight: '100%' }}>
              <UserForm
                isCreating={isCreating}
                form={isCreating ? form : editForm}
                handleCreateUser={handleCreateUser}
                handleUpdateUser={handleUpdateUser}
                handleCancelEdit={handleCancelEdit}
                userFormData={userFormData}
                setUserFormData={setUserFormData}
                files={files}
                editingUser={editingUser}
                handleDeleteFile={handleDeleteFile}
              />
            </EditUserSection>
          </Col>
        )}
        <Col xs={24} lg={(isEditing || isCreating) ? 16 : 24} style={{ width: '100%' }}>
          <UserManagementSection style={{ width: '100%', height: '100%', minHeight: '100%' }}> 
            <Row justify="space-between" align="middle" style={{ marginBottom: '20px' }}>
              <Col>
                <Title level={2}>Gerenciar Usuários</Title>
              </Col>
              <Col>
                {!isCreating && (
                  <StyledButton type="primary" onClick={handleNewUser} size="large" icon={<PlusOutlined />}>Novo Usuário</StyledButton>
                )}
              </Col>
            </Row>
            <Divider />
            <UserTable 
              usersList={usersList} 
              handleEditUser={handleEditUser} 
              handleDeleteUser={handleDeleteUser} 
              setIsModalUploadOpen={()=>setIsModalUploadOpen(true)} // Ajuste para garantir que estamos passando uma função válida
              setEditingUser={setEditingUser} 
            />

          </UserManagementSection>
        </Col>
      </Row>
      <FileUploadModal
        isOpen={isModalUploadOpen}
        onClose={() => setIsModalUploadOpen(false)}
        clientId={editingUser?.id}
        onFileUpload={handleFileUpload}
      />
    </StyledContent>
  );
};

export default Users;
