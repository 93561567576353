import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Input, Button, Checkbox, Typography, Row, Col, Spin, message } from "antd";
import userApi from "../../services/api/userApi";
import useAppSession from "../../stores/useAppSession";
import { useInactivityHandler } from "../../services/utils/inactivityHandler.js";
import set from "lodash/set";
import throttle from "lodash/throttle";

const { Title } = Typography;

const UserProfile = () => {
  const [userData, setUserData] = useState({
    id: "",
    client_id: "",
    nome: "",
    fantasia: "",
    api_key: "",
    active: false,
    template_saudacao: "",
    template_contexto: "",
    template_internet: "",
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { token, clientId, clearSession } = useAppSession();
  const { handleUserInteraction } = useInactivityHandler(clearSession, 500, 10);

  const fetchUserData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await userApi.getCurrentUser(clientId);
      if (response.status === 401 || response.status === 403) {
        clearSession();
        navigate("/login");
      } else if (response.status !== 200) {
        throw new Error("Erro ao carregar dados do usuário");
      }

      const data = response.data;
      setUserData({
        id: data.user.id,
        client_id: data.user.id,
        nome: data.user.nome,
        fantasia: data.user.fantasia,
        api_key: data.user.api_key,
        active: data.user.active,
        template_saudacao: data.user.template_saudacao,
        template_contexto: data.user.template_contexto,
        template_internet: data.user.template_internet,
      });
    } catch (error) {
      console.error("Erro ao carregar dados do usuário:", error);
      message.error("Erro ao carregar dados do usuário");
    } finally {
      setLoading(false);
    }
  }, [clientId, clearSession, navigate]);

  useEffect(() => {
    if (clientId) {
      fetchUserData();
    }
  }, [token, clientId, fetchUserData]);

  const handleSave = async () => {
    try {
      setLoading(true);
      const response = await userApi.updateProfile(userData);

      if (response.status !== 200) {
        throw new Error("Erro ao salvar dados do usuário");
      }

      message.success("Dados do usuário salvos com sucesso");
    } catch (error) {
      console.error("Erro ao salvar dados do usuário:", error);
      message.error("Erro ao salvar dados do usuário");
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setUserData((prevData) => {
      const newData = { ...prevData };
      set(newData, name, type === "checkbox" ? checked : value);
      return newData;
    });
  };

  useEffect(() => {
    const throttledHandler = throttle(handleUserInteraction, 1000);
    const events = ["mousemove", "keypress", "touchstart", "pointermove", "mousedown", "keydown"];
    events.forEach((event) => window.addEventListener(event, throttledHandler));

    return () => {
      events.forEach((event) => window.removeEventListener(event, throttledHandler));
    };
  }, [handleUserInteraction]);

  return (
    <>
      <Row justify="center" style={{ marginTop: "20px" }}>
        <Col span={12}>
          <Title level={2}>Perfil do Usuário</Title>
          {loading ? (
            <Spin tip="Carregando..." />
          ) : (
            <Form layout="vertical">
              <Form.Item label="Client ID">
                <Input
                  name="id"
                  value={userData.id}
                  onChange={handleChange}
                  disabled
                />
              </Form.Item>
              <Form.Item label="API Key">
                <Input
                  name="api_key"
                  value={userData.api_key}
                  onChange={handleChange}
                  disabled
                />
              </Form.Item>
              <Form.Item label="Nome">
                <Input
                  name="nome"
                  value={userData.nome}
                  onChange={handleChange}
                />
              </Form.Item>
              <Form.Item label="Fantasia">
                <Input
                  name="fantasia"
                  value={userData.fantasia}
                  onChange={handleChange}
                />
              </Form.Item>
              <Form.Item label="Ativo">
                <Checkbox
                  name="active"
                  checked={userData.active}
                  onChange={handleChange}
                >
                  Ativo
                </Checkbox>
              </Form.Item>
              <Form.Item label="Template de Saudação">
                <Input.TextArea
                  name="template_saudacao"
                  value={userData.template_saudacao}
                  onChange={handleChange}
                  rows={4}
                />
              </Form.Item>
              <Form.Item label="Template de Contexto">
                <Input.TextArea
                  name="template_contexto"
                  value={userData.template_contexto}
                  onChange={handleChange}
                  rows={4}
                />
              </Form.Item>
              <Form.Item label="Template de Internet">
                <Input.TextArea
                  name="template_internet"
                  value={userData.template_internet}
                  onChange={handleChange}
                  rows={4}
                />
              </Form.Item>
              <Form.Item>
                <Button type="primary" onClick={handleSave} disabled={loading}>
                  Salvar
                </Button>
              </Form.Item>
            </Form>
          )}
        </Col>
      </Row>
    </>
  );
};

export default UserProfile;
