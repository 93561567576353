import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import UserMenu from "../UserMenu/UserMenu.jsx";
import EvaLogo from "../../images/EVA.png";
import {
  HeaderContainer,
  BackButton,
  FooterLogo,
  Logo,
  TitleContainer,
  Title,
} from "./Header";
import useAppSession from "../../stores/useAppSession.js";
import { useIntl } from "react-intl";

const Header = ({
  showUserMenu = true,
  showBackButton = false,
  handleGoBack,
}) => {
  const intl = useIntl();
  const { clearSession } = useAppSession();
  const handleLogout = () => {
    clearSession();
  };

  return (
    <HeaderContainer
      className={`${showUserMenu ? "no-width" : ""} ${
        showBackButton ? "backButton" : ""
      }`}
    >
      <div>
        <TitleContainer>
          <Logo alt="eva logo" src={EvaLogo} />
          <Title>
            {intl.formatMessage({
              id: "header.assistantTitle",
              defaultMessage: "Assistente",
            })}
          </Title>
        </TitleContainer>

        <FooterLogo className={`${showUserMenu ? "" : "noshow"}`}>
          <i>{process.env.REACT_APP_AMBIENTE}</i>
        </FooterLogo>
      </div>

      {showUserMenu && <UserMenu onLogout={handleLogout} />}
      {showBackButton && (
        <BackButton onClick={handleGoBack}>
          <FontAwesomeIcon icon={faArrowLeft} />{" "}
          {intl.formatMessage({
            id: "header.goBackButton",
            defaultMessage: "Voltar",
          })}
        </BackButton>
      )}
    </HeaderContainer>
  );
};

export default Header;
