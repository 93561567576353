import React, { useCallback, useMemo } from "react";
import { Menu, Button, Switch, Select } from "antd";
import {
  MessageOutlined,
  DashboardOutlined,
  CommentOutlined,
  FolderOutlined,
  UploadOutlined,
  FileOutlined,
  SettingOutlined,
  TeamOutlined,
  YoutubeOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import useChat from "../../stores/useChat";
import { useIntl } from "react-intl";
import useAppSession from "../../stores/useAppSession";

const { Option } = Select;

const SidebarMenu = ({ collapsed, isAdmin, openYoutube, openUpload, openFileList }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const {
    habilitaImagens,
    habilitaEmail,
    habilitaZendesk,
    habilitaContexto,
    engine,
    temperature,
    setHabilitaImagens,
    setHabilitaEmail,
    setHabilitaZendesk,
    setHabilitaContexto,
    setEngine,
    setSession,
    setShouldLog,
    setTemperature,
    isTyping,
    botTyping,
  } = useChat();

  const { setSessionId, locale, setLocale } = useAppSession();

  // Uso de useCallback apenas em funções que são passadas diretamente para outros componentes e funções que são potencialmente caras.
  const handleNewChat = useCallback(() => {
    setShouldLog(true);
    navigate("/");
    setSession("");
    setSessionId("");
  }, [navigate, setSession, setSessionId, setShouldLog]);

  // Apenas as funções essenciais foram envolvidas com useCallback.
  const handleOpenDashboard = useCallback(() => {
    navigate("/dashboard");
  }, [navigate]);

  const handleOpenMessageboard = useCallback(() => {
    navigate("/messageboard");
  }, [navigate]);

  // Memorizar os textos do `intl` que são utilizados frequentemente e não mudam com as props.
  const newChatLabel = useMemo(
    () => intl.formatMessage({ id: "sidebar.newChat", defaultMessage: "Novo Chat" }),
    [intl]
  );
  const dashboardLabel = useMemo(
    () => intl.formatMessage({ id: "sidebar.dashboard", defaultMessage: "Dashboard" }),
    [intl]
  );
  const messagesLabel = useMemo(
    () => intl.formatMessage({ id: "sidebar.messages", defaultMessage: "Mensagens" }),
    [intl]
  );

  return (
    <Menu
      theme="dark"
      mode="inline"
      items={[
        {
          key: "1",
          icon: <MessageOutlined />,
          label: newChatLabel,
          onClick: handleNewChat,
          disabled: isTyping || botTyping,
        },
        ...(isAdmin
          ? [
              {
                key: "2",
                icon: <DashboardOutlined />,
                label: dashboardLabel,
                onClick: handleOpenDashboard,
              },
              {
                key: "3",
                icon: <CommentOutlined />,
                label: messagesLabel,
                onClick: handleOpenMessageboard,
              },
              {
                key: "4",
                icon: <FolderOutlined />,
                label: intl.formatMessage({ id: "sidebar.files", defaultMessage: "Arquivos" }),
                children: [
                  {
                    key: "4-1",
                    icon: <UploadOutlined />,
                    label: intl.formatMessage({ id: "sidebar.uploadFiles", defaultMessage: "Carregar arquivos" }),
                    onClick: () => openUpload(true),
                  },
                  {
                    key: "4-2",
                    icon: <FileOutlined />,
                    label: intl.formatMessage({ id: "sidebar.showFiles", defaultMessage: "Mostrar arquivos" }),
                    onClick: () => openFileList(true),
                  },
                ],
              },
              {
                key: "5",
                icon: <SettingOutlined />,
                label: intl.formatMessage({ id: "sidebar.settings", defaultMessage: "Configurações" }),
                children: [
                  {
                    key: "5-1",
                    label: (
                      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Switch checked={habilitaImagens} onChange={setHabilitaImagens} style={{ marginRight: "10px" }} />
                        <span>{intl.formatMessage({ id: "sidebar.enableImageReading", defaultMessage: "Habilitar leitura de imagens" })}</span>
                      </div>
                    ),
                  },
                  {
                    key: "5-2",
                    label: (
                      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Switch checked={habilitaEmail} onChange={setHabilitaEmail} style={{ marginRight: "10px" }} />
                        <span>{intl.formatMessage({ id: "sidebar.enableEmail", defaultMessage: "Habilitar envio de e-mail" })}</span>
                      </div>
                    ),
                  },
                  {
                    key: "5-3",
                    label: (
                      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Switch checked={habilitaZendesk} onChange={setHabilitaZendesk} style={{ marginRight: "10px" }} />
                        <span>{intl.formatMessage({ id: "sidebar.enableZendesk", defaultMessage: "Habilitar zendesk" })}</span>
                      </div>
                    ),
                  },
                  {
                    key: "5-4",
                    label: (
                      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Switch checked={habilitaContexto} onChange={setHabilitaContexto} style={{ marginRight: "10px" }} />
                        <span>{intl.formatMessage({ id: "sidebar.contextOnly", defaultMessage: "Somente contexto" })}</span>
                      </div>
                    ),
                  },
                  {
                    key: "5-5",
                    label: (
                      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <span style={{ marginRight: "10px" }}>
                          {intl.formatMessage({ id: "sidebar.engine", defaultMessage: "Engine" })}
                        </span>
                        <Select className="dark-select" value={engine} style={{ width: 120 }} onChange={setEngine}>
                          <Option value="azure">Azure</Option>
                          <Option value="openai">OpenAI</Option>
                        </Select>
                      </div>
                    ),
                  },
                  {
                    key: "5-6",
                    label: (
                      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <span style={{ marginRight: "10px" }}>
                          {intl.formatMessage({ id: "sidebar.temperature", defaultMessage: "Temperatura" })}
                        </span>
                        <Select
                          className="dark-select"
                          value={temperature}
                          style={{ width: 120 }}
                          onChange={setTemperature}
                        >
                          {Array.from({ length: 11 }, (_, i) => i * 0.1).map((value) => (
                            <Option key={value} value={value}>
                              {value.toFixed(1)}
                            </Option>
                          ))}
                        </Select>
                      </div>
                    ),
                  },
                  {
                    key: "5-7",
                    label: (
                      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <span style={{ marginRight: "10px" }}>
                          {intl.formatMessage({ id: "sidebar.language", defaultMessage: "Idioma" })}
                        </span>
                        <Select
                          className="dark-select"
                          value={locale}
                          style={{ width: 120 }}
                          onChange={setLocale}
                        >
                          <Option value="pt">{intl.formatMessage({ id: "language.portugues", defaultMessage: "Português" })}</Option>
                          <Option value="en">{intl.formatMessage({ id: "language.english", defaultMessage: "Inglês" })}</Option>
                        </Select>
                      </div>
                    ),
                  },
                ],
              },
              {
                key: "6",
                icon: <TeamOutlined />,
                label: intl.formatMessage({ id: "sidebar.workspace", defaultMessage: "Workspace" }),
                onClick: () => navigate("/workspace"),
              },
              {
                key: "8",
                icon: <YoutubeOutlined />,
                label: intl.formatMessage({ id: "sidebar.youtube", defaultMessage: "YouTube" }),
                onClick: () => openYoutube(true),
              },
            ]
          : []),
      ]}
      style={{ flex: 1, overflowY: "auto" }}
    />
  );
};

export default SidebarMenu;
