import React, { useState } from "react";
import userApi from "../../services/api/userApi";
import Header from "../Header/Header.jsx";
import Logo from '../../images/automatizacao-do-atendimento.png';
import MaxxiLogo from '../../images/Logo_2024_Fundos_Claros.png';
import Mundo365 from '../../images/Mundo-365-Parceiro-Microsoft-do-Brasil.png';
import { useIntl } from "react-intl";
import {
  BodyLogin,
  ComercialContext,
  ComercialImage,
  InfoContainer,
  LoginContext,
  LoginContainer,
  LoginButton,
  LoginInput,
  LoginInputContainer,
  LoginLabel,
  ErrorMessage,
  Footer,
  FooterLogo,
  LoginPage,
  LanguageSwitchContainer,
  LanguageButton
} from "./Login.js";
import { Flex } from "antd";

const Login = ({ onLoginSuccess, onLanguageChange }) => {
  const intl = useIntl();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [activeLanguage, setActiveLanguage] = useState("pt");

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleLogin = async () => {
    try {
      const response = await userApi.login(username, password);
      if (response.status !== 200) {
        throw new Error("Falha no login");
      }

      const data = response.data;
      const token = data.message;
      onLoginSuccess(token, username);
    } catch (error) {
      setError(
        intl.formatMessage({
          id: "login.error",
          defaultMessage: "Falha no login. Verifique suas credenciais.",
        })
      );
    }
  };

  const handleLanguageChange = (language) => {
    setActiveLanguage(language); // Atualiza o idioma ativo
    onLanguageChange(language);
  };

  return (
    <LoginPage>
      <Header showUserMenu={false} />
      <BodyLogin>
        <ComercialContext>
          <InfoContainer>
            <h2>
              {intl.formatMessage({
                id: "login.header",
                defaultMessage: "Engine Virtual Attendant",
              })}
            </h2>
            <h3>
              {intl.formatMessage({
                id: "login.subHeader",
                defaultMessage:
                  "Uma nova era de inovação e disrupção do atendimento digital está aqui.",
              })}
            </h3>
            <ComercialImage src={Logo} alt="" />
          </InfoContainer>
        </ComercialContext>
        <LoginContext>
          <LoginContainer>
            <div style={{display:'flex', justifyContent:'space-between'}}>
              <h2>{intl.formatMessage({ id: "login.title", defaultMessage: "Login" })}</h2>
              <LanguageSwitchContainer>
              <LanguageButton
                onClick={() => handleLanguageChange('pt')}
                active={activeLanguage === 'pt'}
              >
                PT
              </LanguageButton>
              <LanguageButton
                onClick={() => handleLanguageChange('en')}
                active={activeLanguage === 'en'}
              >
                EN
              </LanguageButton>
            </LanguageSwitchContainer>
            </div>
            
            <ErrorMessage className={error ? "show" : ""}>{error}</ErrorMessage>
            <LoginInputContainer>
              <LoginInput
                type="text"
                value={username}
                onChange={handleUsernameChange}
                placeholder={intl.formatMessage({
                  id: "login.usernamePlaceholder",
                  defaultMessage: "Usuário",
                })}
              />
              <LoginLabel>
                {intl.formatMessage({
                  id: "login.usernameLabel",
                  defaultMessage: "Usuário",
                })}
              </LoginLabel>
            </LoginInputContainer>
            <LoginInputContainer>
              <LoginInput
                type="password"
                value={password}
                onChange={handlePasswordChange}
                placeholder={intl.formatMessage({
                  id: "login.passwordPlaceholder",
                  defaultMessage: "Senha",
                })}
              />
              <LoginLabel>
                {intl.formatMessage({
                  id: "login.passwordLabel",
                  defaultMessage: "Senha",
                })}
              </LoginLabel>
            </LoginInputContainer>
            <LoginButton onClick={handleLogin}>
              {intl.formatMessage({ id: "login.loginButton", defaultMessage: "Entrar" })}
            </LoginButton>
            
          </LoginContainer>
        </LoginContext>
      </BodyLogin>
      <Footer>
        {intl.formatMessage({ id: "login.footerText", defaultMessage: "Powered by:" })}
        <FooterLogo>
          <img src={MaxxiLogo} alt="" height="30px" />
          <img src={Mundo365} alt="" height="45px" />
        </FooterLogo>
      </Footer>
    </LoginPage>
  );
};

export default Login;
