import React, { useState, useEffect, useRef } from 'react';
import { DatePicker, Layout, Row, Col, Typography, Card, Spin } from 'antd';
import dayjs from 'dayjs';
import evaApi from '../../services/api/evaApi';
import ChannelFilter from '../ChannelFilter/ChannelFilter';
import useAppSession from '../../stores/useAppSession';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';

const { Content } = Layout;
const { Title, Text } = Typography;

const Messageboard = ({ onLogout }) => {
  const intl = useIntl();
  const { clientId, sessionId } = useAppSession();
  const [messages, setMessages] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedChannel, setSelectedChannel] = useState('Todos');
  const [loading, setLoading] = useState(false);
  const inactiveTimerRef = useRef(null);
  const inactivityLimit = 60 * 60 * 1000;

  useEffect(() => {
    const resetTimer = () => {
      if (inactiveTimerRef.current) {
        clearTimeout(inactiveTimerRef.current);
      }
      inactiveTimerRef.current = setTimeout(() => {
        onLogout();
      }, inactivityLimit);
    };
    resetTimer();

    return () => {
      clearTimeout(inactiveTimerRef.current);
    };
  }, [onLogout]);

  useEffect(() => {
    const today = new Date();
    const pastDate = new Date(today);
    pastDate.setDate(today.getDate() - 15);
    setEndDate(today.toISOString().split('T')[0]);
    setStartDate(pastDate.toISOString().split('T')[0]);
  }, []);

  useEffect(() => {
    const fetchMessages = async () => {
      setLoading(true);
      try {
        const response = await evaApi.getGroupedMessages(clientId, startDate, endDate);
        if (response.status === 401 || response.status === 403) {
          onLogout();
        }
        setMessages(response.data.messages);
      } catch (error) {
        console.error('Error fetching messages:', error);
        toast.error(
          intl.formatMessage({
            id: 'messageboard.errorFetchingMessages',
            defaultMessage: 'Erro ao buscar mensagens',
          })
        );
      } finally {
        setLoading(false);
      }
    };
    if (startDate && endDate) {
      fetchMessages();
    }
  }, [startDate, endDate, sessionId, onLogout, clientId, intl]);

  // Função para obter o nome do canal a partir da chave do objeto
  const getChannelFromKey = (key) => {
    if (key.toUpperCase().includes('WEB')) return 'Web';
    if (key.toUpperCase().includes('WIDGET')) return 'Widget';
    if (key.toUpperCase().includes('TELEGRAM')) return 'Telegram';
    if (key.toUpperCase().includes('WHATSAPP')) return 'Whatsapp';
    return 'Outros';
  };

  // Aplicando o filtro de canal selecionado
  const filteredMessages = Object.entries(messages).reduce((acc, [date, sessions]) => {
    const filteredSessions = Object.entries(sessions).reduce((sessionAcc, [sessionKey, messageArray]) => {
      const filteredMessagesArray = messageArray.filter(() => {
        const channel = getChannelFromKey(sessionKey);
        return selectedChannel === 'Todos' || channel === selectedChannel;
      });
      if (filteredMessagesArray.length > 0) {
        sessionAcc[sessionKey] = filteredMessagesArray;
      }
      return sessionAcc;
    }, {});
    if (Object.keys(filteredSessions).length > 0) {
      acc[date] = filteredSessions;
    }
    return acc;
  }, {});

  return (
    <Layout style={{ padding: '24px', minHeight: '100vh' }}>
      <Content>
        <Row justify="space-between" align="middle" style={{ marginBottom: '16px' }}>
          <Col>
            <Title level={2}>
              {intl.formatMessage({
                id: 'messageboard.title',
                defaultMessage: 'Mensagens',
              })}
            </Title>
          </Col>
        </Row>
        <Card style={{ marginBottom: '24px' }}>
          <Title style={{ marginTop: '10px' }} level={4}>
            {intl.formatMessage({
              id: 'messageboard.filterMessages',
              defaultMessage: 'Filtrar mensagens',
            })}
          </Title>
          <Row gutter={[16, 16]} align="middle" style={{ marginTop: '16px' }}>
            <Col flex="auto">
              <ChannelFilter onChannelChange={(channel) => setSelectedChannel(channel)} />
            </Col>
            <Col>
              <DatePicker
                placeholder={intl.formatMessage({
                  id: 'messageboard.startDatePlaceholder',
                  defaultMessage: 'Data inicial',
                })}
                value={startDate ? dayjs(startDate, 'YYYY-MM-DD') : null}
                onChange={(date, dateString) => setStartDate(dateString || null)}
              />
            </Col>
            <Col>
              <DatePicker
                placeholder={intl.formatMessage({
                  id: 'messageboard.endDatePlaceholder',
                  defaultMessage: 'Data final',
                })}
                value={endDate ? dayjs(endDate, 'YYYY-MM-DD') : null}
                onChange={(date, dateString) => setEndDate(dateString || null)}
              />
            </Col>
          </Row>
        </Card>
        {loading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
            <Spin
              tip={intl.formatMessage({
                id: 'messageboard.loadingMessages',
                defaultMessage: 'Carregando mensagens...',
              })}
              size="large"
            />
          </div>
        ) : (
          <div>
            {Object.keys(filteredMessages).length > 0 ? (
              Object.entries(filteredMessages).map(([data, sessions]) => (
                <div key={data}>
                  <Title level={4}>
                    {intl.formatMessage({ id: 'messageboard.date', defaultMessage: 'Data' })}: {data}
                  </Title>
                  {Object.entries(sessions).map(([session, messagesArray]) => (
                    <div key={session}>
                      <Title level={5}>
                        {intl.formatMessage({ id: 'messageboard.session', defaultMessage: 'Sessão' })}: {session}
                      </Title>
                      {messagesArray.map((item, index) => (
                        <Card key={index} style={{ marginBottom: '16px' }}>
                          <Text>
                            <strong>{intl.formatMessage({ id: 'messageboard.messageDate', defaultMessage: 'Data' })}:</strong> {item.data}
                          </Text>
                          <br />
                          <Text>
                            <strong>{intl.formatMessage({ id: 'messageboard.rating', defaultMessage: 'Rating' })}:</strong> {item.rating || 'N/A'}
                          </Text>
                          <br />
                          <Text>
                            <strong>{intl.formatMessage({ id: 'messageboard.question', defaultMessage: 'Pergunta' })}:</strong> {item.pergunta}
                          </Text>
                          <br />
                          <Text>
                            <strong>{intl.formatMessage({ id: 'messageboard.answer', defaultMessage: 'Resposta' })}:</strong> {item.resposta}
                          </Text>
                        </Card>
                      ))}
                    </div>
                  ))}
                </div>
              ))
            ) : (
              <p>
                {intl.formatMessage({
                  id: 'messageboard.noMessagesFound',
                  defaultMessage: 'Nenhuma mensagem encontrada para o canal selecionado.',
                })}
              </p>
            )}
          </div>
        )}
      </Content>
    </Layout>
  );
};

export default Messageboard;
