import styled from "styled-components";
import { Layout, Button } from "antd";

const { Content } = Layout;

export const StyledContent = styled(Content)`
  padding: 40px;
  margin: 0;
  min-height: 280px;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  gap: 20px;

`;

export const UserManagementSection = styled.div`
  flex: 1;
  padding: 24px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
`;

export const EditUserSection = styled.div`
  flex: 1;
  padding: 24px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
`;

export const StyledButton = styled(Button)`
  border-radius: 5px;
  width: 100%;
  @media (min-width: 768px) {
    width: auto;
  }
`;
