// src/services/api/userApi.js

import apiClient from './apiClient';

const userApi = {
  login: (client_id, password) => {
    return apiClient.post('/api/login', { client_id, password });
  },

  // register: (userData) => {
  //   return apiClient.post('/auth/register', userData);
  // },

  getCurrentUser: (client_id) => {
    return apiClient.get(`/api/users/${client_id}`, {client_id});
  },
  
  updateProfile: (userData) => {
    return apiClient.put('/api/users', userData);
  },

  createNewUser: (nome, fantasia) => {
    return apiClient.post(`/api/users`, { nome, fantasia });
  },  

  getUsersList: () =>{
    return apiClient.get(`/api/users`)
  },

  deleteUser: (client_id)=> {
    return apiClient.delete(`/api/users/${client_id}`)
  }
};

export default userApi;