import styled, {keyframes} from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
`;

export const ChatContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  @media (max-width: 768px) {
    width: auto;
  }
`;

export const UploadNotification = styled.div`
  position: fixed;
  top: 20px;
  right: 20px;
  color: #4F9DEB;
  background-color: white;
  padding: 16px;
  border-radius: 4px;
  border: 1px solid #4F9DEB;
  z-index: 1000;
  animation: fadeInOut 3s ease-in-out;

  @keyframes fadeInOut {
    0%, 100% {
      opacity: 0;
    }
    10%, 90% {
      opacity: 1;
    }
  }
`;

const dotFlashing = keyframes`
  0% {
    opacity: 0;
  }
  50%,
  100% {
    opacity: 1;
  }
`;

export const TypingIndicator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  height: 40px;
  color: #4f9deb;
  
  &::before {
    content: ".";
    animation: ${dotFlashing} 1s infinite steps(1, end) both;
    margin-right: 3px;
  }
  &::after {
    content: "..";
    animation: ${dotFlashing} 1s infinite steps(1, end) both;
  }
`;

// Estilo do botão de abrir o menu lateral
export const MenuButton = styled.button`
  color: white;
  border: none;
  font-size: 16px;
  cursor: pointer;
  display: none; 
  margin-bottom: 10px;

  @media (max-width: 768px) {
    display: block;
  }
`;

export const SidebarOverlay = styled.div`
  position: fixed;
  top: 0;
  left: ${(props) => (props.isOpen ? "0" : "-100%")}; /* Oculta ou exibe o menu */
  width: 250px;
  height: 100%;
  background-color: #fff;
  z-index: 1000;
  transition: left 0.3s ease;

  @media (min-width: 769px) {
    position: relative;
    top: auto;
    left: auto;
    width: 250px;
    height: 100%;
  }
`;
