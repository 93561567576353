import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { IntlProvider } from "react-intl";
import Chatbot from "./components/Chatbot/Chatbot.jsx";
import Login from "./components/Login/Login.jsx";
import UserProfile from "./components/UserProfile/UserProfile.jsx";
import Dashboard from "./components/Dashboard/Dashboard.jsx";
import Messageboard from "./components/Messageboard/Messageboard.jsx";
import Widget from "./components/Widget/Widget.jsx";
import useAppSession from "./stores/useAppSession.js";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminLayout from "./components/AdminLayout/AdminLayout.jsx";
import Workspace from "./components/Workspace/Workspace.jsx";
import messagesPt from "./locales/pt-br.json";
import messagesEn from "./locales/en-US.json";

const messages = {
  pt: messagesPt,
  en: messagesEn,
};

const App = () => {
  const { locale, setLocale } = useAppSession();

  const handleLanguageChange = (language) => {
    setLocale(language);
  };

  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      <Router>
        <RoutesComponent onLanguageChange={handleLanguageChange} />
      </Router>
    </IntlProvider>
  );
};

const RoutesComponent = ({ onLanguageChange }) => {
  const location = useLocation();
  const {
    token,
    clientId,
    sessionId,
    isAdmin,
    setToken,
    setClientId,
    setSessionId,
    clearSession,
  } = useAppSession();
  const navigate = useNavigate();

  const handleLoginSuccess = async (token, client_id) => {
    setToken(token);
    setClientId(client_id);
    localStorage.setItem("token", token);
    navigate("/");
  };

  const handleLogout = () => {
    clearSession();
  };

  const handleSessionId = (session) => {
    setSessionId(session);
  };

  if (!token && location.pathname !== "/widget") {
    return (
      <Login
        onLoginSuccess={handleLoginSuccess}
        onLanguageChange={onLanguageChange}
      />
    );
  }

  return (
    <>
      <Routes>
        <Route
          path="/login"
          element={
            <Login
              onLoginSuccess={handleLoginSuccess}
              onLanguageChange={onLanguageChange}
            />
          }
        />
        <Route
          path="/"
          element={
            <AdminLayout>
              <Chatbot onSessionId={handleSessionId} onLogout={handleLogout} />
            </AdminLayout>
          }
        />
        <Route
          path="/user-profile"
          element={
            <AdminLayout>
              <UserProfile onLogout={handleLogout} />
            </AdminLayout>
          }
        />
        {isAdmin && (
          <>
            <Route
              path="/dashboard"
              element={
                <AdminLayout>
                  <Dashboard onLogout={handleLogout} />
                </AdminLayout>
              }
            />
            <Route
              path="/messageboard"
              element={
                <AdminLayout>
                  <Messageboard onLogout={handleLogout} />
                </AdminLayout>
              }
            />
            <Route
              path="/workspace"
              element={
                <AdminLayout>
                  <Workspace onLogout={handleLogout} />
                </AdminLayout>
              }
            />
          </>
        )}
        <Route
          path="/widget"
          element={
            <Widget
              clientId={clientId}
              sessionId={sessionId}
              onSessionId={handleSessionId}
            />
          }
        />
        {/* Redirecionar para a página inicial se o usuário não for admin */}
        {!isAdmin && <Route path="*" element={<Navigate to="/" />} />}
      </Routes>
      <ToastContainer />
    </>
  );
};

export default App;
